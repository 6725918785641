import React from "react";
import { Row, Col, Button, Form, Input, Modal } from "antd";
import AllPosts from "./Blogs/all-posts";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    console.log(this.props.selectedStock);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  onFinish = values => {
    const MSG = {
      to: [
        "leads@megatheron.co.za",
        //"ianels182@gmail.com",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      //to: [  //"ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New Newsletter Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Newsletter Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Newsletter Enquiry from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                         
                        
                             
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,

        email: "",
      });
    });
  };
  render() {
    return (
      <div>
        <footer id="wrap-footer" className="desktop-view">
          {/* <div
            style={{ margin: "auto", display: "inline-flex", width: "100vw" }}
          >
            <Row
              style={{
                maxWidth: "100em",
                margin: "auto",
                marginTop: "2em",
              }}
            >
              <Col span={1}></Col>
              <Col span={6}>
                <h6 style={{ textAlign: "left", marginTop: "1em" }}>
                  Stay up to date on the latest blogs
                </h6>
                <h1
                  style={{
                    textAlign: "left",
                    fontSize: "35px",
                    lineHeight: "0.2em",
                  }}
                >
                  Blogs & Reviews
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    color: "#4F5665",
                    fontSize: "16px",
                    lineHeight: "1.2em",
                  }}
                >
                  All things motoring – from reviews and helpful articles to
                  news and special offers.
                </p>{" "}
                <br />
                <Link to='./blogs'>
                  <Button
                    style={{
                      
                      border: "1px solid #F91700",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0.3em",
                      backgroundColor: "#F91700",
                      color: "white",
                      height: "55px",
                      paddingTop: "0.2em",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "2em",
                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    View All Blogs
                  </Button>{" "}
                </Link>
              </Col>
              <Col span={2}></Col>
              <Col span={15}>
                <AllPosts />
              </Col>
            </Row>
          </div>{" "} */}
          <div
            //className='banner-item-footer banner-bg-4 color-inher desktop-view'
            style={{
              height: "14em",
              padding: 0,
              marginBottom: "-1.5em",
              marginTop: "2em",
            }}
          >
            <div style={{ backgroundColor: "rgb(242, 244, 244)" }}>
              <Row
                justify="center"
                style={{
                  maxWidth: "65em",
                  margin: "auto",
                  paddingTop: "2em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={16}>
                  <h6 style={{ textAlign: "left" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{ width: "8.5em", marginTop: "-1em" }}
                    />
                    {/*Recommender*/}
                  </h6>
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      lineHeight: "0.2em",
                    }}
                  >
                    NEED MORE ASSISTANCE?
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      color: "black",
                      fontSize: "15px",
                      lineHeight: "1em",
                    }}
                  >
                    Get in Touch with us today.
                  </p>
                </Col>
                <Col span={4}>
                  <Link to={"/contactUs"}>
                    <Button
                      style={{

                        border: "1px solid #201a20",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "#201a20",
                        color: "white",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "3em",
                        marginLeft: "2em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Get in Touch
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          <iframe
            style={{
              filter: "grayscale(100%)",
              borderRadius: "0em",
              marginLeft: "-0em",
              marginTop: "2em",
              width: "100vw",
            }}
            className="contact-hidden"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.17124836777!2d26.664352415044014!3d-26.866299783147625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e96f10578eb30e7%3A0xa5b72df90734ef7d!2s30%20Leask%20St%2C%20Klerksdorp%20Central%2C%20Klerksdorp%2C%202570!5e0!3m2!1sen!2sza!4v1659003956408!5m2!1sen!2sza"
            height="300"
            title="map"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          {/* <Row
            justify="center"
            style={{
              maxWidth: "60em",
              margin: "auto",
              paddingTop: "6em",
              paddingBottom: "1em",
            }}
          >
            <Col span={8}>
              <h1
                style={{
                  paddingTop: 0,
                  textAlign: "left",
                  fontFamily: "Roboto",
                  fontSize: "35px",
                  lineHeight: "0.9em",
                  fontWeight: 500,
                }}
              >
                Stay Updated
              </h1>{" "}
            </Col>
            <Col span={14}>
              <Form
                onFinish={this.handleSubmit}
                name="login"
                initialValues={{
                  remember: true,
                }}
                // onFinish={submitForm}
                style={{
                  display: "inline-flex",
                  margin: "auto",
                  marginTop: "-1em",
                  marginBottom: "1.5em",
                }}
              >
                <Form.Item
                  name="email"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      min: 3,
                      message: "",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    style={{
                      height: "40px",
                      width: "25em",
                      borderRadius: "0em",
                      paddingLeft: "1.5em",
                      boxShadow: "none",
                      border: "1px solid black",
                    }}
                    id="standard-basic"
                    placeholder="Email"
                  />
                </Form.Item>{" "}
                <Button
                  style={{

                    border: "1px solid rgb(32, 26, 32)",
                    fontSize: "15px",
                    fontWeight: 600,
                    borderRadius: "0.3em",
                    backgroundColor: "rgb(32, 26, 32)",
                    color: "white",
                    height: "40px",
                    paddingTop: "0.2em",
                    paddingLeft: "2.5em",
                    paddingRight: "2.5em",
                    textShadow: "none",
                    margin: "auto",
                    marginTop: "0em",
                    marginLeft: "1em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form>{" "}
              <p
                style={{
                  color: "black",
                  marginBottom: "2.5em",
                  marginTop: "-2.5em",
                  fontSize: "13px",
                  lineHeight: "1.5em",
                }}
              >
                By submitting this form, you agree to our{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "black" }} to="/terms">
                    Terms of Use
                  </Link>
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "black" }} to="/privacy-policy">
                    Privacy Statement
                  </Link>
                </span>
                .
              </p>{" "}
            </Col>
          </Row> */}
          <div className="footer-top">
            <div className="container-custom">
              <div className="p-l-r">
                <div className="row">
                  <div className="col-xs-0 col-sm-1 col-md-1 m-t-lg-60"></div>
                  <div className="col-xs-12 col-sm-3 col-md-3 m-t-lg-60">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{
                        width: "20em",
                        marginBottom: "1.5em",
                        marginTop: "-1.5em",
                      }}
                    />
                    {/*<h3>Company Info</h3>*/}
                    <p style={{ fontSize: "13px", color: "black" }}>
                      At Mega Theron Group, our clients walk in as strangers and
                      leave as friends. We strive to build a long lasting,
                      on-going relationship with all our clients, based on
                      honesty integrity and good service.
                    </p>

                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          className="social-icon list-inline "
                          style={{ marginTop: "0em" }}
                        >
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href="https://www.facebook.com/MegaTheronGroup/"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-facebook"
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                            //href='https://twitter.com/MegatheronKLD'
                            //target='_blank'
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-twitter"
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href="https://www.linkedin.com/company/megatherongroup?originalSubdomain=za"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-linkedin"
                              />
                            </a>
                          </li>
                        </ul>{" "}
                        <p
                          style={{
                            fontSize: "11px",
                            fontWeight: 400,

                            lineHeight: "0em",
                            marginTop: "1.5em",

                            color: "black",
                          }}
                        >
                          © 2024 Mega Theron. All rights reserved
                        </p>
                        <p
                          style={{
                            fontSize: "11px",
                            fontWeight: 400,
                            lineHeight: "0em",
                            color: "black",
                            marginTop: "-1em"
                          }}
                        >

                          Created By <a style={{ color: "red", fontWeight: 600, textDecoration: "underline" }} href='https://squatchwebsites.com/' target='_blank'>Squatch Websites</a>
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-xs-0 col-sm-1 col-md-1 m-t-lg-60"></div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-60">
                    <h3>Legal</h3>

                    <ul className="list-footer-default">
                      <li>
                        <Link to="/legal-notice">Legal Notice</Link>
                      </li>
                      <li>
                        <Link to="/POPIA-manual">PAIA & POPIA</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Statement</Link>
                      </li>{" "}
                      <li>
                        <Link to="/terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/e&oe-policy">E&OE Policy</Link>
                      </li>{" "}
                    </ul>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-60">
                    <h3>Services</h3>

                    <ul className="list-footer-default">
                      <li>
                        <Link to={"/servicecenter"}>Service Center</Link>
                      </li>
                      <li>
                        <Link to={"/sellacar"}>Sell your Car</Link>
                      </li>
                      <li>
                        <Link to={"/downloadCenter"}>Download Center</Link>
                      </li>

                      {/* <li>
                        <Link to={"/blogs"}>Blog</Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-60">
                    <h3>More</h3>

                    <ul className="list-footer-default">
                      {/*<li>
                      <a>Brands</a>
                    </li>*/}
                      <li>
                        <Link to={"/contactUs"}>Contact Us</Link>
                      </li>
                      {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                    </ul>
                  </div>{" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/footer-back.png`}
                    alt="logo"
                    style={{
                      zIndex: -1,
                      left: 0,
                      marginTop: "-13em",
                      position: "absolute",
                      width: "100vw",
                      minWidth: "110em",
                      minHeight: "50em",
                    }}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </footer>{" "}
        <footer id="wrap-footer" className="mobile-view">
          {/* <div style={{ margin: "auto", textAlign: "center" }}>
            <h6
              style={{
                textAlign: "center",
                margin: "auto",
                marginTop: "1em",
              }}
            >
              Stay up to date on the latest blogs
            </h6>
            <h1
              style={{
                textAlign: "center",
                fontSize: "35px",
                lineHeight: "0.2em",
              }}
            >
              Blogs & Reviews
            </h1>
            <p
              style={{
                textAlign: "center",
                color: "#4F5665",
                fontSize: "16px",
                lineHeight: "1.2em",
                padding: "1em",
                paddingBottom: "0em",
              }}
            >
              All things motoring – from reviews and helpful articles to news
              and special offers.
            </p>{" "}
            <br />
            <Link to='./blogs'>
              <Button
                style={{
                  
                  border: "1px solid #F91700",
                  fontSize: "15px",
                  fontWeight: 600,
                  borderRadius: "0.3em",
                  backgroundColor: "#F91700",
                  color: "white",
                  height: "55px",
                  paddingTop: "0.2em",
                  paddingLeft: "3em",
                  paddingRight: "3em",
                  textShadow: "none",
                  margin: "auto",
                  marginTop: "1em",
                  marginBottom: "2em",
                }}
                type='primary'
                htmlType='submit'
              >
                View All Blogs
              </Button>{" "}
            </Link>
            <Col span={24}>
              <AllPosts />
            </Col>
          </div> */}
          <iframe
            style={{
              borderRadius: "0em",
              marginLeft: "-0em",
              marginTop: "2em",
              width: "100vw",
            }}
            className="contact-hidden"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.17124836777!2d26.664352415044014!3d-26.866299783147625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e96f10578eb30e7%3A0xa5b72df90734ef7d!2s30%20Leask%20St%2C%20Klerksdorp%20Central%2C%20Klerksdorp%2C%202570!5e0!3m2!1sen!2sza!4v1659003956408!5m2!1sen!2sza"
            height="300"
            title="map"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          {/* <Row
            justify="center"
            style={{
              margin: "auto",
              paddingTop: "6em",
              paddingBottom: "1em",
            }}
          >
            <Col span={24} style={{ textAlign: "center" }}>
              <h1
                style={{
                  paddingTop: 0,
                  fontWeight: 500,
                  fontFamily: "Roboto",
                  fontSize: "35px",
                  lineHeight: "0.9em",
                }}
              >
                Stay Updated
              </h1>{" "}
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Form
                onFinish={this.handleSubmit}
                name="login"
                initialValues={{
                  remember: true,
                }}
                // onFinish={submitForm}
                style={{
                  display: "inline-flex",
                  margin: "auto",
                  marginTop: "-1em",
                  marginBottom: "1.5em",
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="namepackage"
                      hasFeedback
                      rules={[
                        {
                          min: 3,
                          message: "",
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          height: "40px",
                          width: "25em",
                          borderRadius: "0em",
                          paddingLeft: "1.5em",
                          boxShadow: "none",
                          border: "1px solid black",
                        }}
                        id="standard-basic"
                        placeholder="Email"
                      />
                    </Form.Item>{" "}
                  </Col>
                  <Col span={24}>
                    <Button
                      style={{

                        border: "1px solid rgb(32, 26, 32)",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "rgb(32, 26, 32)",
                        color: "white",
                        height: "40px",
                        paddingTop: "0.2em",
                        paddingLeft: "2.5em",
                        paddingRight: "2.5em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "0em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>{" "}
              <p
                style={{
                  color: "black",
                  marginBottom: "2.5em",
                  marginTop: "0em",
                  padding: "1em",
                  fontSize: "13px",
                  lineHeight: "1.5em",
                }}
              >
                By submitting this form, you agree to our{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "black" }} to="/terms">
                    Terms of Use
                  </Link>
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "black" }} to="/privacy-policy">
                    Privacy Statement
                  </Link>
                </span>
                .
              </p>{" "}
            </Col>
          </Row> */}
          <div className="footer-top">
            <div className="container-custom">
              <div className="p-l-r">
                <div className="row" style={{ margin: "auto" }}>
                  <div
                    className="col-xs-12 col-sm-13 col-md-12 m-t-lg-60"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{
                        width: "62vw",
                        marginBottom: "1.5em",
                        marginTop: "-1.5em",
                      }}
                    />
                    {/*<h3>Company Info</h3>*/}
                    <p
                      style={{
                        fontSize: "13px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      At Mega Theron Group, our clients walk in as strangers and
                      leave as friends. We strive to build a long lasting,
                      on-going relationship with all our clients, based on
                      honesty integrity and good service.
                    </p>

                    <div className="row" style={{ margin: "auto" }}>
                      <div className="col-xs-12 col-sm-13 col-md-12">
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,

                            lineHeight: "0em",
                            marginTop: "0.5em",

                            color: "black",
                          }}
                        >
                          © 2024 Mega Theron. All rights reserved
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "0em",
                            color: "black",
                            marginTop: "-0.7em"
                          }}
                        >

                          Created By <a style={{ color: "red", fontWeight: 600, textDecoration: "underline" }} href='https://squatchwebsites.com/' target='_blank'>Squatch Websites</a>
                        </p>
                        <ul
                          className="social-icon list-inline text-align-lg-center"
                          style={{
                            margin: "auto",
                            marginTop: "0.5em",
                            width: "100%",
                          }}
                        >
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href="https://www.facebook.com/MegaTheronGroup/"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-facebook"
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                            //href='https://twitter.com/MegatheronKLD'
                            //target='_blank'
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-twitter"
                              />
                            </a>
                          </li>
                          <li style={{}}>
                            <a
                              href="https://www.linkedin.com/company/megatherongroup?originalSubdomain=za"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-linkedin"
                              />
                            </a>
                          </li>
                        </ul>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 m-t-lg-10 text-center">
                    <h3>Legal</h3>

                    <ul className="list-footer-default">
                      <li>
                        <Link to="/legal-notice">Legal Notice</Link>
                      </li>
                      <li>
                        <Link to="/POPIA-manual">PAIA & POPIA</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Statement</Link>
                      </li>{" "}
                      <li>
                        <Link to="/terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/e&oe-policy">E&OE Policy</Link>
                      </li>{" "}
                    </ul>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 m-t-lg-10 text-center">
                    <h3>Services</h3>

                    <ul className="list-footer-default">
                      <li>
                        <Link to={"/servicecenter"}>Service Center</Link>
                      </li>
                      <li>
                        <Link to={"/sellacar"}>Sell your Car</Link>
                      </li>
                      <li>
                        <Link to={"/downloadCenter"}>Download Center</Link>
                      </li>

                      <li>
                        <Link to={"/blogs"}>Blog</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 m-t-lg-10 text-center">
                    <h3>More</h3>

                    <ul className="list-footer-default">
                      {/*<li>
                      <a>Brands</a>
                    </li>*/}
                      <li>
                        <Link to={"/contactUs"}>Contact Us</Link>
                      </li>
                      {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                    </ul>
                  </div>{" "}
                  {/*<p
                    style={{
                      fontSize: "11px",
                      fontWeight: 400,
                      
                      lineHeight: "0em",
                      marginTop: "1.5em",
                      
                      color: "black",
                    }}
                  >
                    © 2024 Mega Theron. All rights reserved
                  </p>{" "}*/}
                </div>
              </div>
            </div>{" "}
          </div>{" "}
          <img
            src={`${process.env.PUBLIC_URL}/images/footer-back.png`}
            alt="logo"
            style={{
              zIndex: -1,
              left: 0,

              width: "100vw",
            }}
          />
        </footer>{" "}
        <Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="Logo"
            style={{ width: "65%", margin: "auto" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for subscribing to the
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              The Mega Theron Group Newsletter.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              We'll keep you updated on all our exclusive offerings.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal>
      </div>
    );
  }
}

export default Footer;
