import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./client";
import { Col, Row, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);
  document.documentElement.scrollTop = 0;
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
      title,

      slug,
      mainImage{
        asset->{
        _id,
        url
      }
    },
    "name": author->name,
  }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <Row style={{ marginTop: "0em" }}>
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Col
              sm={24}
              md={8}
              style={{
                width: "100%",
                padding: "0.5em",
                height: "25em",
                marginTop: "1em",
              }}
              key={index}
            >
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%)",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "25em",
                    zIndex: -9999,
                    borderRadius: "0em",
                    opacity: 0.5,
                  }}
                  src={post.mainImage.asset.url}
                  alt=''
                />{" "}
              </div>
              <h2
                className='main-blogs-header'
                style={{
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "3em",
                  paddingTop: "2.3em",
                  paddingLeft: "0.5em",
                  margin: "auto",
                  maxWidth: "100%",
                  position: "absolute",
                  paddingTop: "2em",
                  top: "0em",
                  color: "white",
                  //background:
                  //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                  marginTop: "11.5em",
                  fontFamily: "Roboto",
                  lineHeight: "1em",
                }}
              >
                {post.name}
              </h2>
              <h2
                className='main-blogs-header'
                style={{
                  fontSize: "17px",
                  fontWeight: 500,
                  padding: "3em",
                  paddingLeft: "0.5em",
                  margin: "auto",
                  maxWidth: "100%",
                  position: "absolute",
                  paddingTop: "1.5em",
                  top: "0em",
                  color: "white",
                  //background:
                  //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                  marginTop: "11.5em",
                  fontFamily: "Roboto",
                  lineHeight: "1.3em",
                }}
              >
                {post.title}
              </h2>
              <Link to={"/" + post.slug.current} key={post.slug.current}>
                <h2
                  className='main-blogs-header'
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    padding: "3em",
                    right: 0,
                    paddingLeft: "0.5em",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",

                    top: "8em",
                    color: "white",
                    //background:
                    //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "11.5em",
                    fontFamily: "Roboto",
                    lineHeight: "1em",
                  }}
                >
                  Read more <SwapRightOutlined />
                </h2>
              </Link>
            </Col>
          ))}
      </Row>
    </div>
  );
}

class Blogs extends Component {
  render() {
    return (
      <React.Fragment>
        {" "}
        <div
          className='banner-item banner-2x banner-bg-download-center color-inher'
          style={{ padding: "30px 15px 0px 15px" }}
        >
          <h3 className='f-weight-300'>
            <strong>Blog center</strong>
            <br />
            STAY UP TO DATE ON THE LATEST BLOGS
          </h3>
        </div>
        <Row
          style={{
            margin: "auto",
            marginTop: "3em",
          }}
        >
          <AllPosts />
        </Row>
      </React.Fragment>
    );
  }
}

export default Blogs;
