import React from "react";
import StockItemCard from "./StockItemCard";
import CarItemList from "./StockItemList";

function renderStockCards(stock) {
  return stock.NewUsed[0] === "NEW" ? (
    <StockItemCard key={stock.ID} stock={stock} />
  ) : null;
}

function renderStockList(stock) {
  return stock.NewUsed[0] === "NEW" ? (
    <CarItemList key={stock.ID} stock={stock} />
  ) : null;
}

const StockList = (props) => {
  if (props.displayType === "cards")
    return <div>{props.stocklist.map((stock) => renderStockCards(stock))}</div>;
  else if (props.displayType === "list")
    return <div>{props.stocklist.map((stock) => renderStockList(stock))}</div>;

  // Removed the console.log statement here
};

export default StockList;
