import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeFromWishList,
  addToBasket,
  fetchSingleStock,
} from "../redux/actions";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishlistproducts: {},
      stock: props.stock,
    };
  }
  componentDidMount() {
    var { stock } = this.props;
  }
  renderItem(stock) {
    return (
      <div key={stock.ID} className='row m-lg-0 overl bor-r'>
        <div className='col-sm-5 col-md-5 col-lg-5 cart-item'>
          <div className='row'>
            <div className='col-sm-3 col-md-3 col-lg-3'>
              <li onClick={() => this.props.fetchSingleStock(stock)}>
                <Link to={`/stocko`}>
                  <a href=' ' className='cart-img-prev'>
                    {stock.frontLeftImage ? (
                      <img
                        src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                        alt=''
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                        alt=''
                      />
                    )}
                  </a>
                </Link>
              </li>
            </div>
            <div className='col-sm-9 col-md-9 col-lg-9 p-lg-0'>
              <div className='stock-name'>
                <h5 style={{ lineHeight: "70px" }}>
                  <Link to={`/stocko`} style={{ color: "black" }}>
                    <i
                      //style={stock.body !== undefined ? { pointerEvents: "none" } : {}}
                      onClick={() => this.props.fetchSingleStock(stock)}
                    >
                      {stock.Brand}
                    </i>
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/*<div className='col-sm-2 col-md-2 col-lg-2 cart-item'>
          <p>Stock nr. {stock.stockNumber}</p>
        </div>*/}
        <div className='col-sm-3 col-md-3 col-lg-3 cart-item'>
          {stock.RegionImage ? (
            <img
              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
              alt=''
            />
          ) : (
            <p>
              {stock.brand},{"  "}
              {stock.Region}
            </p>
          )}
        </div>

        <div className='col-sm-2 col-md-2 col-lg-2 cart-item'>
          <p>
            <CurrencyFormat
              value={parseFloat(stock.Price)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              //format='R ### ### ### ###'
              renderText={(value) => (
                <div style={{ lineHeight: "100px" }}>{value}</div>
              )}
            />
          </p>
        </div>

        {/*<div className='col-sm-1 col-md-1 col-lg-1 cart-item'>
          <i
            className='fa fa-eye cart-remove-btn'
            style={stock.body !== undefined ? { pointerEvents: "none" } : {}}
            onClick={() => {
              this.props.addToBasket(stock, 1);
            }}
          ></i>
        </div>*/}
        <div className='col-sm-1 col-md-1 col-lg-1 cart-item'>
          <Link to={`/stocko`} style={{ color: "black" }}>
            <i
              className='fa fa-list cart-remove-btn'
              //style={stock.body !== undefined ? { pointerEvents: "none" } : {}}
              onClick={() => this.props.fetchSingleStock(stock)}
            ></i>
          </Link>
        </div>
        <div className='col-sm-1 col-md-1 col-lg-1 cart-item'>
          <i
            className='fa fa-remove cart-remove-btn'
            onClick={() => this.props.removeFromWishList(stock)}
          />
        </div>
      </div>
    );
  }

  render() {
    const parentStyle = {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    const { stock } = this.props;
    return (
      <div className='wrap-body-inner'>
        <div>
          <div className='row'>
            <div className='col-lg-6'>
              <ul className='ht-breadcrumb pull-left'>
                <li className='home-act'>
                  <Link to={"/"}>
                    <i className='fa fa-home' aria-hidden='true'></i>
                  </Link>
                </li>
                <li className='home-act'>
                  <Link to={"/stock"}>Cars</Link>
                </li>
                <li className='active'>WishList</li>
              </ul>
            </div>
          </div>
        </div>
        <section className='block-cart m-b-lg-50 m-t-lg-30 m-t-xs-0'>
          <div>
            <div className='heading'>
              <h3>Wishlist</h3>
            </div>
            {this.props.wishlistproducts.length > 0 ? (
              <div style={parentStyle}>
                <div className='display-inline-block width-100'>
                  {this.props.wishlistproducts.map((e) => this.renderItem(e))}

                  {/*
              <a href=" " className="ht-btn ht-btn-default pull-right">
              Proceed to check out
            </a>
            */}
                </div>
              </div>
            ) : (
              <div className='banner-item banner-2x no-bg '>
                <h2 className='f-weight-300'>
                  <i className='fa fa-heart-o m-r-lg-10'> </i>No Favourites
                </h2>
                <Link
                  to={"/stock"}
                  className='ht-btn ht-btn-default ht-btn-2x m-t-lg-35'
                >
                  View All Cars
                </Link>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}
function mapStateToProps({ wishlistproducts, fetchSingleStock }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  addToBasket,
  removeFromWishList,
  fetchSingleStock,
})(WishList);
