import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import Fade from "react-reveal/Fade";
import CurrencyFormat from "react-currency-format";
class CarItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
    };
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    var { stock } = this.props;
    if (
      this.props.wishlistproducts.filter(function (e) {
        return e._id === stock._id;
      }).length === 1
    ) {
      this.setState({ isInWishList: true });
    }
  }

  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render() {
    var heartStyle = {};
    if (!this.state.isHovered) {
      heartStyle = {
        color: "#F91700",
        fontWeight: "bold",
      };
    }

    const { stock } = this.props;
    return stock.webVisible ? (
      <div className="product-item hover-img" style={{ padding: "0.5em" }}>
        <div className="row">
          {" "}
          {stock.onSale === true ? (
            <span
              style={{
                background: "rgb(254, 95, 92)",
                borderRadius: "5em",
                padding: "0.7em",
                paddingTop: "1.1em",
                paddingBottom: "1em",
                position: "absolute",
                zIndex: 1,
                left: 10,
                top: 10,
                color: "white",
                fontSize: 11,
                zIndex: 1,
              }}
            >
              SALE
            </span>
          ) : null}
          {stock.condition ? (
            <span
              style={{
                background:
                  stock.condition === "New"
                    ? "rgba(254, 95, 92, 0.829)"
                    : stock.condition === "Demo"
                      ? "rgba(109, 109, 109, 0.829)"
                      : "rgba(109, 109, 109, 0.829)",
                borderRadius: "0em",
                padding: "0.3em",
                paddingRight: "0.8em",
                paddingLeft: "0.8em",
                position: "absolute",
                zIndex: 1,
                right: 10,
                top: 10,
                width: "4.3em",
                color: "white",
              }}
            >
              {stock.condition.toUpperCase()}
            </span>
          ) : null}
          {stock.modelYear ? (
            <span
              style={{
                background: "rgba(0, 0, 0, 0.529)",
                borderRadius: "0em",
                padding: "0.3em",
                paddingRight: "0.8em",
                paddingLeft: "0.8em",
                position: "absolute",
                zIndex: 1,
                right: stock.condition ? 75 : 10,
                top: 10,
                color: "white",
              }}
            >
              {stock.modelYear}
            </span>
          ) : null}
          <div className="col-sm-12 col-md-4 col-lg-4">
            <li onClick={() => this.props.fetchSingleStock(stock)}>
              <Link to={`/stocks/${stock._id}`}>
                <a className="product-img">
                  {stock.frontLeftImage ? (
                    <img
                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontLeftImage}`}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                      alt=""
                    />
                  )}
                </a>
              </Link>
            </li>
          </div>
          <ul className="absolute-caption">
            <li onClick={() => this.props.fetchSingleStock(stock)}>
              <Link to={`/stocks/${stock._id}`} style={{ color: "black" }}>
                <i className="fa fa-info-circle" />
                View
              </Link>
            </li>

            <li
              onMouseEnter={this.mouseHover.bind(this)}
              onMouseLeave={this.mouseUnHover.bind(this)}
            >
              <i
                id={JSON.stringify(stock)}
                className={
                  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                }
                style={heartStyle}
                onClick={this.ToggleCarWishlist.bind(this)}
              />
              WishList
            </li>
          </ul>
          <div className="col-sm-12 col-md-8 col-lg-8">
            <div className="product-caption text-align-lg-left">
              {/*<ul className="rating">
                <li className="active">
                  <i className="fa fa-star"></i>
                </li>
                <li className="active">
                  <i className="fa fa-star"></i>
                </li>
                <li className="active">
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
                <li>
                  <i className="fa fa-star"></i>
                </li>
              </ul>*/}
              <h4
                style={{
                  paddingBottom: "0em",
                  marginBottom: "0em",
                  //borderRight: "1px solid lightgrey",
                }}
                className=""
              //style={{ marginBottom: "0em", marginTop: "-1em" }}
              >
                <span
                //className='f-28'
                >
                  {stock.make} {stock.series}
                  {/*{stock.series}*/}
                  <br />
                  <p className="f-16" style={{ marginBottom: "0em" }}>
                    {stock.modelDesc}
                  </p>
                </span>
              </h4>{" "}
              <span
                className="f-23  m-t-lg-0 mn-cl"
                style={{
                  float: "right",
                  paddingRight: "1em",
                }}
              >
                <CurrencyFormat
                  value={stock.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  //format='R### ### ### ###'
                  renderText={value => <div>{value}</div>}
                />
              </span>
              <span
                className="f-15"
                style={{
                  float: "left",
                  fontWeight: 400,
                  color: "black",

                  //marginTop: "-2em",
                }}
              >
                {/*<img
                  src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.branchImage}`}
                  alt=''
                  style={{
                    position: "absolute",
                    marginTop: "-2.5em",

                    objectFit: "cover",
                    height: "2em",
                  }}
                />{" "}*/}
                {stock.brand}, {stock.branch}
              </span>
              <br />
              <span
                className="f-12"
                style={{
                  width: "100%",
                  float: "left",
                  fontWeight: 400,
                  color: "black",
                  height: "4.5em",
                  display: "-webkit-box",
                  textOverflow: "ellipsis",
                  paddingRight: "3em",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",

                  WebkitLineClamp: 3,
                }}
              >
                {stock.comments}
              </span>
              <br />
              <ul className="static-caption m-t-lg-35 text-center">
                <li>
                  <i className="fa fa-clock-o"></i>
                  {stock.modelYear}
                </li>
                <li>
                  <i className="fa fa-tachometer"></i>
                  {stock.transmission}
                </li>
                <li>
                  <i className="fa fa-road"></i>13,000
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(CarItemList);