import React, { Component } from "react";
import { Row, Col, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";

class LegalNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='wrap-body-inner'>
        <div>
          <div className='row'>
            <div>
              <div
                style={{
                  position: "absolute",
                  margin: "auto",
                  left: 0,
                  top: "0em",
                  zIndex: -1,
                  right: 0,
                  boxShadow: "inset 0 0 100px 150px white",
                  position: "absolute",
                  width: "80vw",
                  height: "90vh",
                }}
              ></div>{" "}
              <div
                id='home'
                className='home-div'
                style={{
                  //maxHeight: "100em",
                  marginTop: "0em",
                  //width: "100vw",
                }}
              >
                {" "}
                <Row
                  className='home-row'
                  style={{
                    maxWidth: "90em",
                    margin: "auto",
                  }}
                >
                  <Col
                    sm={24}
                    md={24}
                    style={{
                      margin: "auto",
                      textAlign: "left",
                      marginTop: "3em",
                      marginBottom: "3em",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "0em",
                        color: "#3F3F3F",
                        fontWeight: 700,
                        fontSize: "30px",
                        padding: "30px",
                        paddingBottom: 20,
                        paddingTop: 0,
                        lineHeight: "1em",
                      }}
                    >
                      Errors and Omissions Excepted (E&OE) Policy
                    </h2>

                    <div
                      style={{
                        margin: "auto",
                        fontWeight: 100,
                        color: "black",
                        fontWeight: 400,
                        fontSize: "17px",
                        maxWidth: "90em",
                        padding: "30px",
                        paddingTop: 0,
                        textAlign: "left",
                      }}
                    >

                      At Mega Theron Group, we strive to provide accurate and up-to-date information on our vehicle retail website, including monthly instalment amounts. However, it is important to note that despite our best efforts, errors or omissions may occur. This Errors and Omissions Excepted (E&OE) policy now includes specific terms regarding monthly instalment amounts. <br />
                      <br />

                      <br />
                      1. Information Accuracy:

                      We make every effort to ensure that the information provided on our website is accurate, reliable, and current, including monthly instalment amounts. However, we do not warrant the accuracy or completeness of this information. Monthly instalment amounts are estimates, and the actual monthly instalment will depend on factors such as interest rates, balloon payments, and deposits.
                      <br />
                      <br />
                      2. Monthly Instalment Estimates:

                      The monthly instalment amounts displayed on our website are provided as estimates for general informational purposes only. These estimates are based on certain assumptions and may not reflect the final, actual monthly instalment amounts.
                      <br />
                      <br />
                      3. Factors Influencing Monthly Instalments:

                      The actual monthly instalment for a vehicle purchase may vary based on factors such as interest rates, balloon payments, deposit amounts, and individual creditworthiness. Users are encouraged to consult with our financial services department or relevant representatives to obtain accurate and personalized information regarding monthly instalment amounts.
                      <br />
                      <br />
                      4. Pricing and Availability:

                      While we strive to display accurate pricing information, errors may occur. Prices and availability of vehicles, as well as monthly instalment estimates, are subject to change without notice. We reserve the right to correct any errors, inaccuracies, or omissions and to update information at any time.
                      <br />
                      <br />
                      5. Third-Party Information:

                      Our website may contain links to third-party websites or content that may provide additional information about financing options. We do not endorse or make any representations regarding the accuracy or reliability of information provided by third parties.
                      <br />
                      <br />
                      6. Modifications to the E&OE Policy:

                      We reserve the right to modify, update, or amend this E&OE policy at any time without prior notice. Users are encouraged to review this policy regularly to stay informed of any changes.
                      <br />
                      <br />

                      7. Limitation of Liability:

                      Mega Theron Group shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our website or the information presented on it, including monthly instalment estimates.
                      <br />
                      <br />
                      8. Contact Information:

                      If you identify any errors or have concerns about the information presented on our website, please contact us immediately at info@megatheron.co.za.
                      <br />
                      <br />
                      By using our website, you agree to accept the terms and conditions outlined in this Errors and Omissions Excepted (E&OE) policy. This policy is effective as of December 1, 2024.
                      <br />

                    </div>
                    <Divider
                      orientation='right'
                      style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
                    >
                      <Link to='/home'>
                        <Button
                          style={{
                            float: "right",
                            height: "2.7em",
                            width: "10em",
                            fontSize: "17px",
                            fontWeight: 500,
                            borderRadius: "3em",
                            color: "white",
                            border: "none",
                            marginTop: "1em",
                            marginBottom: "1em",
                            backgroundColor: "black",
                            textShadow: "none",
                            boxShadow: "none",
                          }}
                        >
                          Back to Home
                        </Button>
                      </Link>
                    </Divider>
                  </Col>
                </Row>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNotice;
