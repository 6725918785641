export const FETCH_USER = "fetch_user";
export const FETCH_SURVEYS = "fetch_surveys";
export const LOCATION = "location";
export const FETCH_PRODUCTS = "fetch_products";
export const FETCH_SINGLE_PRODUCTS = "fetch_single__products";
export const BASKET_ADD = "basket_add";
export const BASKET_REMOVE_ITEM = "basket_remove_item";
export const FETCH_STOCK = "fetch_stock";
export const FETCH_SINGLE_STOCK = "fetch_single_stock";
export const BASKET_EDIT_ITEM = "basket_edit_item";
export const WISHLIST_ADD_PRODUCT = "wishlist_add_product";
export const WISHLIST_REMOVE_PRODUCT = "wishlist_remove_product";
export const SELECTED_PRODUCT = "selected_product";
export const SELECTED_STOCK = "selected_stock";
export const FETCH_FILTRED_STOCK = "fetch_filtred_stock";
export const FILTER_ACTIVATION = "filter_activation";
