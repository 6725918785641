import React from "react";
import StockItemCard from "./StockItemCard";
import CarItemList from "./StockItemList";

function renderStockCards(stock) {
  return (
    <StockItemCard key={stock.ID} stock={stock} />
  );
}

function renderStockList(stock) {
  return (
    <CarItemList key={stock.ID} stock={stock} />
  );
}

const StockList = (props) => {
  if (props.displayType === "cards")
    return <div>{props.stocklist.map((stock) => renderStockCards(stock))}</div>;
  else if (props.displayType === "list")
    return <div>{props.stocklist.map((stock) => renderStockList(stock))}</div>;
  console.log("props.stocklist");
};

export default StockList;
