import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  Modal,
  BackTop,
  Image,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    padding: 0.5em !important;
    border-radius: 0.5em !important;
    background-image: url(../images/home-banner-service.png);
    border-radius: 1em;
    color: black;
    background-color: white;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center;
    width: 43em;
    height: 46em;
  }
  .ant-modal-body {
    text-align: center !important;
    margin-top: 34em !important;
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  @media (max-width: 1100px) {
    .ant-modal-content {
      width: 95vw;
      margin-left: 40px;
      height: 30em;
    }
    .ant-modal-body {
      text-align: center !important;
      margin-top: 28em !important;
      padding: 24px;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }
`;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
      visible: false,
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  async componentDidMount() {
    try {
      document.documentElement.scrollTop = 0;
      window.updateCarousel();
      window.reRenderRangeSlider();

      // Set loading to true
      this.setState({ loading: true });

      const [stockResponse, pexUnitsResponse] = await Promise.all([
        fetch(
          "https://www.devvcsappcloud.com/mtgroup/api/requisition/extractxmldataclient",
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        ),
        fetch(
          "https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        ),
      ]);
      if (pexUnitsResponse.ok) {
        const pexUnitsResult = await pexUnitsResponse.json();
        this.setState({ pexUnits: pexUnitsResult.allPexUnit, loading: false });
      }
      if (stockResponse.ok) {
        const stockResult = await stockResponse.json();
        const allStockData = stockResult.map((item) => item.root.row).flat();
        if (allStockData.length > 0) {
          // Apply data processing logic here to filter and set the data to state
          // Example logic provided in the previous response
          const filteredStockData = allStockData.filter((row) => row);

          this.setState({ stock: filteredStockData });
        } else {
          throw new Error("Unexpected response structure or empty stock data.");
        }
      } else {
        throw new Error("Failed to fetch stock data.");
      }

      // Set loading to false
      this.setState({ loading: false });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors as needed, e.g., show an error message to the user
      this.setState({ loading: false, error: error.message });
    }
  }

  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    //window.location.assign("./");
  };
  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "#F91700",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];
    return (
      <div>
        <div className="wrap-body-inner desktop-view">
          <div>
            {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
            {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          </div>
          <div className="product product-grid product-grid-2 stock m-t-lg-20 p-t-sm-35 m-b-lg-20 ">
            <div className="heading">
              <h3>Featured Vehicles</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.DTCreated) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.DTCreated) - new Date(a.DTCreated)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                whiteSpace: "nowrap",
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.Brand.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.Brand.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.Brand.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.Brand.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.Brand.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.Brand.includes("MT003") ||
                                      stock.Brand.includes("MT001")
                                      ? "-2.5em"
                                      : stock.Brand.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.Brand.includes("MT003") ||
                                      stock.Brand.includes("MT001")
                                      ? "8.5em"
                                      : stock.Brand.includes("MT005")
                                        ? "7em"
                                        : stock.Brand.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div
            className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-0 "
            style={{ zIndex: 999 }}
          >
            <div className="heading">
              <h3>SUV MANIA</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.BodyType.includes("SUV")) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.Year) - new Date(a.Year)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.DealershipID.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.DealershipID.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.DealershipID.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.DealershipID.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.DealershipID.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "-2.5em"
                                      : stock.DealershipID.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "8.5em"
                                      : stock.DealershipID.includes("MT005")
                                        ? "7em"
                                        : stock.DealershipID.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>

          <div className="banner-item banner-2x banner-bg-10 color-inher m-b-lg-50">
            <h3 className="f-weight-300">
              <strong>New or Pre-owned?</strong>
            </h3>
            <p style={{ margin: "auto" }}>
              Choose from our extensive range of new, pre-owned or demo cars,
              with offers to suit your budget! We are serious about quality –
              all our vehicles pass stringent verification and multi-point
              checks.
            </p>
            <Row
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "3em",
                textAlign: "center",
              }}
            >
              <Col span={8}>
                {" "}
                <Fade left>
                  <Card
                    bordered={false}
                    hoverable
                    style={{
                      width: "20em",
                      padding: "1.5em",
                      paddingTop: "3em",
                      margin: "auto",
                      borderRadius: "1em",
                    }}
                    cover={
                      <span>
                        <Meta
                          title="New Cars"
                          description="Choose from our extensive range of new cars."
                        />
                        <Link to={"/stock-new"}>
                          <Button size="large" style={{ marginTop: "1.7em" }}>
                            Search Now
                          </Button>
                        </Link>
                      </span>
                    }
                  >
                    <img
                      style={{ width: "14em", marginLeft: "-0.5em" }}
                      alt="example"
                      src={NewCar}
                    />
                  </Card>{" "}
                </Fade>
              </Col>

              <Col span={8}>
                {" "}
                <Fade bottom>
                  <Card
                    bordered={false}
                    hoverable
                    style={{
                      width: "20em",
                      padding: "1.5em",
                      paddingTop: "3em",
                      margin: "auto",
                      borderRadius: "1em",
                    }}
                    cover={
                      <span>
                        <Meta
                          title="Used Cars"
                          description="Choose from our extensive range of quality pre-owned cars."
                        />
                        <Link to={"/stock-used"}>
                          <Button size="large" style={{ marginTop: "1.7em" }}>
                            Search Now
                          </Button>
                        </Link>
                      </span>
                    }
                  >
                    <img
                      style={{ width: "13em", marginTop: "0.5em" }}
                      alt="example"
                      src={UsedCar}
                    />
                  </Card>{" "}
                </Fade>
              </Col>

              <Col span={8}>
                {" "}
                <Fade right>
                  <Card
                    bordered={false}
                    hoverable
                    style={{
                      width: "20em",
                      padding: "1.5em",
                      paddingTop: "3em",
                      margin: "auto",
                      borderRadius: "1em",
                    }}
                    cover={
                      <span>
                        <Meta
                          title="Demo Cars"
                          description="Choose from our extensive range of demo cars."
                        />
                        <Link to={"/stock-demo"}>
                          <Button size="large" style={{ marginTop: "1.7em" }}>
                            Search Now
                          </Button>
                        </Link>
                      </span>
                    }
                  >
                    <img
                      style={{ width: "17em" }}
                      alt="example"
                      src={DemoCar}
                    />
                  </Card>{" "}
                </Fade>
              </Col>
            </Row>
          </div>
          <div className="">
            <div style={{ backgroundColor: "#F2F4F4" }}>
              <Row
                justify="center"
                style={{
                  maxWidth: "60em",
                  margin: "auto",
                  paddingTop: "2em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={4}>
                  <img
                    src={ThinkImg}
                    alt="Logo"
                    style={{
                      display: "inline",

                      width: "23em",
                      marginTop: "-5.8em",
                      marginBottom: "-11em",
                      marginLeft: "-15em",
                    }}
                  />
                </Col>
                <Col span={16}>
                  <h6 style={{ textAlign: "left" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{ width: "8.5em", marginTop: "-1em" }}
                    />
                    {/*Recommender*/}
                  </h6>
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      lineHeight: "0.2em",
                    }}
                  >
                    Not Sure Which Car to buy?
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      color: "#4F5665",
                      fontSize: "15px",
                      lineHeight: "1em",
                    }}
                  >
                    Let’s help to get you driving away in your perfect car.
                  </p>
                </Col>
                <Col span={4}>
                  <Link to={"/contactUs"}>
                    <Button
                      style={{
                        border: "1px solid #F91700",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "#F91700",
                        color: "white",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "3em",
                        marginLeft: "2em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Chat to a specialist
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          <div
            className="banner-item banner-2x banner-bg-10 color-inher"
            style={{ paddingBottom: "4em" }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              style={{ width: "12em", marginBottom: "1em" }}
            />
            <h3 className="f-weight-300">
              <strong>
                Trusted by <span style={{ color: "#F91700" }}>the best</span>
              </strong>
            </h3>
            <p style={{ margin: "auto" }}>View our trusted branches.</p>
            <Row
              style={{
                maxWidth: "80em",
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                color: "white",
              }}
            >
              <Col span={8} style={{ padding: "1.5em" }}>
                <Fade left>
                  <div className="banner-bg-mt">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageMegaGroup}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Pre-Owned Branches
                      </h6>
                      <li
                        // //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-0.8em",
                          fontSize: "20px",
                        }}
                      >
                        <Link
                          to="/mega-theron-traders"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Mega Theron Traders
                        </Link>
                      </li>
                      <li
                        // //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-0em",
                          fontSize: "20px",
                        }}
                      >
                        <Link to="/potchefstroom" style={{ fontSize: "20px", color: "white" }}>
                          Potchefstroom
                        </Link>
                      </li>
                      <li
                        // //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <Link to="/mahikeng" style={{ fontSize: "20px", color: "white" }}>
                          Mahikeng
                        </Link>
                      </li>
                      <li
                        // //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <Link to="/lichtenburg" style={{ fontSize: "20px", color: "white" }}>
                          Lichtenburg
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
              <Col span={8} style={{ padding: "1.5em" }}>
                <Fade bottom>
                  <div className="banner-bg-kia">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageKia}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired in. Schedule A
                      Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Branches
                      </h6>
                      <li
                        // //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-1em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://kiamahikeng.megatheron.co.za"
                          style={{ fontSize: "20px" }}
                        >
                          Mahikeng
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
              <Col span={8} style={{ padding: "1.5em" }}>
                {" "}
                <Fade right>
                  <div className="banner-bg-mahindra">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageMahindra}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Branches
                      </h6>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-1em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.megatheron.co.za/mahindramahikeng"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Mahikeng
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
            </Row>
            <Row
              style={{
                maxWidth: "80em",
                margin: "auto",
                textAlign: "center",
                color: "white",
              }}
            >
              <Col span={8} style={{ padding: "1.5em" }}>
                <Fade left>
                  <div className="banner-bg-chery">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageChery}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Branches
                      </h6>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-1em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.chery.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Klerksdorp
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
              <Col span={8} style={{ padding: "1.5em" }}>
                {" "}
                <Fade bottom>
                  <div className="banner-bg-gwm">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageGWM}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Branches
                      </h6>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-1em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://gwmklerksdorp.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Klerksdorp
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.gwmpotchefstroom.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Potchefstroom
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.gwmmahikeng.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Mahikeng
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.gwmlichtenburg.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Lichtenburg
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
              <Col span={8} style={{ padding: "1.5em" }}>
                <Fade right>
                  <div className="banner-bg-haval">
                    {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                      return (
                        <img
                          style={{
                            width: "15em",
                            height: "5em",
                            objectFit: "contain",
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageHaval}`}
                          alt="logo"
                        />
                      );
                    })}
                    {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>*/}
                    <ul>
                      <h6
                        className="f-weight-400"
                        style={{ textAlign: "center", marginTop: "0.4em" }}
                      >
                        Branches
                      </h6>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "-1em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.havalklerksdorp.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Klerksdorp
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.havalpotchefstroom.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Potchefstroom
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.havalmahikeng.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Mahikeng
                        </a>
                      </li>
                      <li
                        //onClick={onClickBranch}
                        className="f-weight-600 branch-name"
                        style={{
                          textAlign: "left",
                          marginTop: "0em",
                          fontSize: "20px",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://www.havallichtenburg.megatheron.co.za/"
                          style={{ fontSize: "20px", color: "white" }}
                        >
                          Lichtenburg
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Col>
            </Row>{" "}
            <Fade bottom>
              {/* <Row
                className="banner-bg-service"
                style={{
                  maxWidth: "77em",
                  margin: "auto",
                  marginTop: "1.5em",
                  textAlign: "left",
                  color: "white",
                  padding: "0em",
                }}
              >
                <Col
                  span={12}
                  style={{ padding: "3.5em", paddingTop: "4.5em" }}
                >
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo-white.png`}
                      alt="logo"
                      style={{ width: "16em" }}
                    />
                    <h3 className="f-weight-300" style={{ marginTop: "0.3em" }}>
                      <strong>SERVICE CENTERs</strong>
                      <br />
                    </h3>
                    <p style={{ fontSize: "13px", marginTop: "0.7em" }}>
                      We believe movement inspires ideas. In pursuing New
                      experiences. Get moving. Get inspired. Schedule A Service.
                    </p>
                  </div>
                </Col>
                <Col span={6} style={{ paddinLeft: "1.5em" }}>
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "1em" }}
                    >
                      KLERKSDORP
                    </h6>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Chery
                      </Link>
                    </li>{" "}
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      GWM
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      HAVAL
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      JAC
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Used
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "1em" }}
                    >
                      Potchefstroom
                    </h6>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      GWM
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      HAVAL
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Used
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col span={6} style={{}}>
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{
                        textAlign: "center",
                        marginTop: "1em",
                      }}
                    >
                      MAHIKENG
                    </h6>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Kia
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Mahindra
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      GWM
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      HAVAL
                      </Link>
                    </li>
                    <li
                      onClick={this.showModal}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                      Used
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row> */}
              <div
                className="banner-item banner-2x banner-bg-download-center color-inher"
                style={{
                  paddingBottom: 0,
                  marginTop: "1em",
                }}
              >
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo.png`}
                  alt="logo"
                  style={{ width: "12em" }}
                />
                <h3 className="f-weight-300">
                  <strong>SERVICE <span style={{ color: "#F91700" }}>CENTERS</span></strong>
                </h3>
              </div>
              <Row
                gutter={10}
                style={{
                  margin: "auto",
                  marginTop: "1.5em",
                }}
              >
                <Col
                  xs={24}
                  md={8}
                  style={{
                    margin: "auto",
                  }}
                >
                  <Link to='/servicecenter'>
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-klerksdorp.jpg`}
                      alt="service-center-image"
                    />
                  </Link>
                </Col>
                <Col
                  xs={24}
                  md={8}
                  style={{
                    margin: "auto",
                    marginTop: window.isPhone ? "1em" : "",
                  }}
                >
                  <Link to='/servicecenter'>
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-potchefstroom.jpg`}
                      alt="service-center-image"
                    />
                  </Link>
                </Col>
                <Col
                  xs={24}
                  md={8}
                  style={{
                    margin: "auto",
                    marginTop: window.isPhone ? "1em" : "",
                  }}
                >
                  <Link to='/servicecenter'>
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-mahikeng.jpg`}
                      alt="service-center-image"
                    />
                  </Link>
                </Col>
              </Row>
            </Fade>
          </div>
          <div
            className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-0 "
            style={{ zIndex: 999 }}
          >
            <div className="heading">
              <h3>HOT DEMO VEHICLES</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.NewUsed.includes("DEMO")) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.DTCreated) - new Date(a.DTCreated)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.DealershipID.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.DealershipID.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.DealershipID.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.DealershipID.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.DealershipID.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "-2.5em"
                                      : stock.DealershipID.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "8.5em"
                                      : stock.DealershipID.includes("MT005")
                                        ? "7em"
                                        : stock.DealershipID.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <Row style={{ maxWidth: "60em", margin: "auto", marginTop: "5em" }}>
            {/* <Col span={12}>
              <div
                style={{ height: "31em" }}
                className="banner-item banner-2x banner-bg-express-store  m-b-lg-50"
              >
                <div
                  className="m-b-lg-10"
                  style={{ fontSize: "20px", color: "#F91700" }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                  EXPRESS STORE
                </div>
                <h3 className="f-weight-300">
                  <strong> Safe & Secure</strong>
                  <br />
                  <span style={{ fontSize: "23px" }}>
                    Buying accessories made simple
                  </span>
                </h3>
                <p>Instant Pricing - No Hassle - Safe & Secure</p>
                <a className="ht-btn ht-btn-default">Shop Now</a>
              </div>
            </Col> */}

            <Col span={24}>
              <div
                style={{
                  height: "22em",
                  textAlign: "center",
                  paddingTop: 30,
                  marginBottom: "3em",
                }}
                className="banner-item banner-2x banner-bg-service-center color-inher"
              >
                <div
                  className="m-b-lg-10"
                  style={{ fontSize: "20px", color: "white" }}
                >
                  <i className="fa fa-wrench" aria-hidden="true"></i> SERVICE
                  CENTER
                </div>
                <h3 className="f-weight-300">
                  <strong> Book a Service </strong>
                  <br />
                  <span style={{ fontSize: "23px" }}>
                    Get the service your car deserves
                  </span>
                </h3>
                <p style={{ margin: "auto" }}>
                  Booking online is quick and convenient
                </p>
                <Link to={"/servicecenter"}>
                  <a className="ht-btn ht-btn-white">Book Service</a>
                </Link>
              </div>
            </Col>
          </Row>
        </div>{" "}
        <div className="wrap-body-inner mobile-view">
          <div>
            {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
            {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          </div>
          <div
            className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-0 "
            style={{ zIndex: 999 }}
          >
            <div className="heading">
              <h3>Featured Vehicles</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.DTCreated) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.DTCreated) - new Date(a.DTCreated)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.DealershipID.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.DealershipID.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.DealershipID.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.DealershipID.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.DealershipID.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "-2.5em"
                                      : stock.DealershipID.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "8.5em"
                                      : stock.DealershipID.includes("MT005")
                                        ? "7em"
                                        : stock.DealershipID.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div
            className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-0 "
            style={{ zIndex: 999 }}
          >
            <div className="heading">
              <h3>SUV MANIA</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.BodyType.includes("SUV")) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.Year) - new Date(a.Year)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.DealershipID.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.DealershipID.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.DealershipID.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.DealershipID.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.DealershipID.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "-2.5em"
                                      : stock.DealershipID.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "8.5em"
                                      : stock.DealershipID.includes("MT005")
                                        ? "7em"
                                        : stock.DealershipID.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className="banner-item banner-2x banner-bg-10 color-inher m-b-lg-0">
            <h3 className="f-weight-300">
              <strong>New or Pre-owned?</strong>
            </h3>
            <p style={{ margin: "auto" }}>
              Choose from our extensive range of new, pre-owned or demo cars,
              with offers to suit your budget! We are serious about quality –
              all our vehicles pass stringent verification and multi-point
              checks.
            </p>
            <Row
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "3em",
                textAlign: "center",
              }}
            >
              <Col span={24}>
                <Card
                  bordered={false}
                  hoverable
                  style={{
                    width: "20em",
                    padding: "1.5em",
                    paddingTop: "3em",
                    margin: "auto",
                    borderRadius: "1em",
                  }}
                  cover={
                    <span>
                      <Meta
                        title="New Cars"
                        description="Choose from our extensive range of new cars."
                      />
                      <Link to={"/stock-new"}>
                        <Button size="large" style={{ marginTop: "1.7em" }}>
                          Search Now
                        </Button>
                      </Link>
                    </span>
                  }
                >
                  <img style={{ width: "14em" }} alt="example" src={NewCar} />
                </Card>
              </Col>
              <Col span={24}>
                <Card
                  bordered={false}
                  hoverable
                  style={{
                    width: "20em",
                    padding: "1.5em",
                    paddingTop: "3em",
                    margin: "auto",
                    borderRadius: "1em",
                  }}
                  cover={
                    <span>
                      <Meta
                        title="Used Cars"
                        description="Choose from our extensive range of quality pre-owned cars."
                      />
                      <Link to={"/stock-used"}>
                        <Button size="large" style={{ marginTop: "1.7em" }}>
                          Search Now
                        </Button>
                      </Link>
                    </span>
                  }
                >
                  <img style={{ width: "12em" }} alt="example" src={UsedCar} />
                </Card>
              </Col>
              <Col span={24}>
                <Card
                  bordered={false}
                  hoverable
                  style={{
                    width: "20em",
                    padding: "1.5em",
                    paddingTop: "3em",
                    margin: "auto",
                    borderRadius: "1em",
                  }}
                  cover={
                    <span>
                      <Meta
                        title="Demo Cars"
                        description="Choose from our extensive range of demo cars."
                      />
                      <Link to={"/stock-demo"}>
                        <Button size="large" style={{ marginTop: "1.7em" }}>
                          Search Now
                        </Button>
                      </Link>
                    </span>
                  }
                >
                  <img style={{ width: "17em" }} alt="example" src={DemoCar} />
                </Card>
              </Col>
            </Row>
          </div>
          <div className="">
            <div style={{ backgroundColor: "#F2F4F4", margin: "auto" }}>
              <Row
                justify="center"
                style={{
                  maxWidth: "60em",
                  margin: "auto",
                  paddingTop: "2em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    src={ThinkImg}
                    alt="Logo"
                    style={{
                      display: "inline",

                      width: "23em",
                      //marginTop: "-6.5em",
                      //marginBottom: "-11em",
                      //marginLeft: "-15em",
                    }}
                  />
                </Col>
                <Col span={24}>
                  <h6 style={{ textAlign: "center" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{ width: "8em", marginTop: "0em" }}
                    />
                    <br /> <br />
                    Recommender
                  </h6>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "35px",
                      lineHeight: "1em",
                    }}
                  >
                    Not Sure Which Car to buy?
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#4F5665",
                      fontSize: "15px",
                      lineHeight: "1em",
                    }}
                  >
                    Let’s help to get you driving away in your perfect car.
                  </p>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Link to={"/contactUs"}>
                    <Button
                      style={{
                        border: "1px solid #F91700",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0.3em",
                        backgroundColor: "#F91700",
                        color: "white",
                        height: "55px",
                        paddingTop: "0.2em",
                        paddingLeft: "3em",
                        paddingRight: "3em",
                        textShadow: "none",
                        margin: "auto",
                        marginTop: "2em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Chat to a specialist
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          <div className="banner-item banner-2x banner-bg-10 color-inher">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              style={{ width: "12em", marginBottom: "1em" }}
            />
            <h3 className="f-weight-300">
              <strong style={{ lineHeight: "0em" }}>
                Trusted by
                <br />
                <span style={{ color: "#F91700" }}>the best</span>
              </strong>
            </h3>
            <p style={{ margin: "auto" }}>View our trusted branches.</p>
            <Row
              style={{
                maxWidth: "80em",
                margin: "auto",
                marginTop: "1em",
                textAlign: "center",
                color: "white",
              }}
            >
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-mt">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageMegaGroup}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Pre-Owned Branches
                    </h6>
                    <li
                      // //onClick={onClickBranch}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-0.8em",
                        fontSize: "20px",
                      }}
                    >
                      <Link
                        to="/mega-theron-traders"
                        style={{ fontSize: "20px" }}
                      >
                        Mega Theron Traders
                      </Link>
                    </li>
                    <li
                      // //onClick={onClickBranch}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to="/potchefstroom" style={{ fontSize: "20px" }}>
                        Potchefstroom
                      </Link>
                    </li>
                    <li
                      // //onClick={onClickBranch}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to="/mahikeng" style={{ fontSize: "20px" }}>
                        Mahikeng
                      </Link>
                    </li>
                    <li
                      // //onClick={onClickBranch}
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "0em",
                        fontSize: "20px",
                      }}
                    >
                      <Link to="/lichtenburg" style={{ fontSize: "20px" }}>
                        Lichtenburg
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-kia">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        key={index}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageKia}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Branches
                    </h6>
                    <li
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://kiamahikeng.megatheron.co.za"
                        style={{ fontSize: "20px" }}
                      >
                        Mahikeng
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-mahindra">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageMahindra}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Branches
                    </h6>
                    <li
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://www.megatheron.co.za/mahindramahikeng"
                        style={{ fontSize: "20px" }}
                      >
                        Mahikeng
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                maxWidth: "80em",
                margin: "auto",
                textAlign: "center",
                color: "white",
              }}
            >
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-chery">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageChery}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Branches
                    </h6>
                    <li
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://www.chery.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Klerksdorp
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-gwm">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageGWM}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Branches
                    </h6>
                    <li
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://gwmklerksdorp.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Klerksdorp
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.gwmpotchefstroom.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Potchefstroom
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.gwmmahikeng.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Mahikeng
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.gwmlichtenburg.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Lichtenburg
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={24} style={{ padding: "0em", marginBottom: "1em" }}>
                <div className="banner-bg-haval">
                  {this.state.pexUnits.slice(1, 2).map((pexUnits, index) => {
                    return (
                      <img
                        style={{
                          width: "15em",
                          height: "4em",
                          objectFit: "contain",
                        }}
                        src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.branchImageHaval}`}
                        alt="logo"
                      />
                    );
                  })}
                  {/*<p style={{ fontSize: "13px", marginTop: "1em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>*/}
                  <ul>
                    <h6
                      className="f-weight-400"
                      style={{ textAlign: "center", marginTop: "0.4em" }}
                    >
                      Branches
                    </h6>
                    <li
                      className="f-weight-600 branch-name"
                      style={{
                        textAlign: "left",
                        marginTop: "-1em",
                        fontSize: "20px",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://www.havalklerksdorp.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Klerksdorp
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.havalpotchefstroom.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Potchefstroom
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.havalmahikeng.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Mahikeng
                      </a>
                    </li>
                    <li
                      className="f-weight-600 branch-name"
                      style={{ textAlign: "left", marginTop: "0em" }}
                    >
                      <a
                        target="_blank"
                        href="https://www.havallichtenburg.megatheron.co.za/"
                        style={{ fontSize: "20px" }}
                      >
                        Lichtenburg
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <div
              className="banner-item banner-2x banner-bg-download-center color-inher"
              style={{
                paddingBottom: 0,
                marginTop: "1em",
              }}
            >
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="logo"
                style={{ width: "12em" }}
              />
              <h3 className="f-weight-300">
                <strong>SERVICE <span style={{ color: "#F91700" }}>CENTERS</span></strong>
              </h3>
            </div>
            <Row
              gutter={10}
              style={{
                margin: "auto",
                marginTop: "1.5em",
              }}
            >
              <Col
                xs={24}
                md={8}
                style={{
                  margin: "auto",
                }}
              >
                <Link to='/servicecenter'>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-klerksdorp.jpg`}
                    alt="service-center-image"
                  /></Link>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{
                  margin: "auto",
                  marginTop: window.isPhone ? "1em" : "",
                }}
              >
                <Link to='/servicecenter'>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-potchefstroom.jpg`}
                    alt="service-center-image"
                  /></Link>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{
                  margin: "auto",
                  marginTop: window.isPhone ? "1em" : "",
                }}
              >
                <Link to='/servicecenter'>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={`${process.env.PUBLIC_URL}/Service-center-images/service-center-mahikeng.jpg`}
                    alt="service-center-image"
                  />
                </Link>
              </Col>
            </Row>
            {/* <Row
              className="banner-bg-service"
              style={{
                maxWidth: "77em",
                margin: "auto",
                textAlign: "left",
                color: "white",
                padding: "0em",
              }}
            >
              <Col
                span={24}
                style={{
                  padding: "3.5em",
                  paddingTop: "2.5em",
                  textAlign: "center",
                }}
              >
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo-white.png`}
                    alt="logo"
                    style={{ width: "100%" }}
                  />
                  <h3 className="f-weight-300" style={{ marginTop: "0.3em" }}>
                    <strong>SERVICE CENTERs</strong>
                    <br />
                  </h3>
                  <p style={{ fontSize: "13px", marginTop: "0.7em" }}>
                    We believe movement inspires ideas. In pursuing New
                    experiences. Get moving. Get inspired. Schedule A Service.
                  </p>
                </div>
              </Col>
              <Col span={24} style={{ paddinLeft: "1.5em" }}>
                <ul>
                  <h6
                    className="f-weight-400"
                    style={{ textAlign: "center", marginTop: "-1em" }}
                  >
                    KLERKSDORP
                  </h6>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "-1em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Chery
                    </Link>
                  </li>{" "}
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    GWM
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    HAVAL
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    JAC
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Used
                    </Link>
                  </li>
                </ul>
                <ul>
                  <h6
                    className="f-weight-400"
                    style={{ textAlign: "center", marginTop: "1em" }}
                  >
                    Potchefstroom
                  </h6>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "-1em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    GWM
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    HAVAL
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Used
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col span={24} style={{}}>
                <ul>
                  <h6 className="f-weight-400" style={{ textAlign: "center" }}>
                    MAHIKENG
                  </h6>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "-1em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Kia
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Mahindra
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    GWM
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    HAVAL
                    </Link>
                  </li>
                  <li
                    onClick={this.showModal}
                    className="f-weight-600 branch-name"
                    style={{
                      textAlign: "center",
                      marginTop: "0em",
                      fontSize: "20px",
                    }}
                  >
                    <Link to='/klerksdorp' style={{ fontSize: "20px" }}>
                    Used
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row> */}
          </div>
          <div
            className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-0 "
            style={{ zIndex: 999 }}
          >
            <div className="heading">
              <h3>HOT DEMO VEHICLES</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock
                  .filter((stock) => stock.NewUsed.includes("DEMO")) // Filter cars with DTCreated
                  .sort((a, b) => new Date(b.DTCreated) - new Date(a.DTCreated)) // Sort by DTCreated
                  .slice(0, 10) // Select the newest 10 cars
                  .map((stock, index) => (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
                        {stock.onSale === true ? (
                          <span
                            style={{
                              background: "rgb(254, 95, 92)",
                              borderRadius: "5em",
                              padding: "0.7em",
                              paddingTop: "1.1em",
                              paddingBottom: "1em",
                              position: "absolute",
                              left: 10,
                              top: 10,
                              color: "white",
                              fontSize: 11,
                            }}
                          >
                            SALE
                          </span>
                        ) : null}
                        {stock.NewUsed ? (
                          <span
                            style={{
                              background:
                                stock.NewUsed === "New"
                                  ? "#f919007d"
                                  : stock.NewUsed === "Demo"
                                    ? "#f919007d"
                                    : "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: 10,
                              top: 158,
                              width: "4.3em",
                              color: "white",
                            }}
                          >
                            {stock.NewUsed}
                          </span>
                        ) : null}
                        {stock.Year ? (
                          <span
                            style={{
                              background: "#f919007d",
                              borderRadius: "0em",
                              padding: "0.3em",
                              paddingRight: "0.8em",
                              paddingLeft: "0.8em",
                              position: "absolute",
                              right: stock.NewUsed ? 75 : 10,
                              top: 158,
                              color: "white",
                            }}
                          >
                            {stock.Year}
                          </span>
                        ) : null}
                        <Link
                          className="product-img"
                          onClick={() => {
                            this.props.fetchSingleStock(stock);
                            window.scrollTo(0, 0); // Scroll to the top
                          }}
                          to={`/stocko/${stock.ID}`}
                        >
                          <img
                            src={
                              stock.Pic[0]
                                ? stock.Pic[0]._
                                : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                            }
                            alt=""
                          />
                        </Link>
                        <div className="product-caption">
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => {
                                this.props.fetchSingleStock(stock);
                                window.scrollTo(0, 0); // Scroll to the top
                              }}
                              to={`/stocko/${stock.ID}`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stock.Brand}
                                <p
                                  className="f-14"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "3em",
                                    overflow: "hidden",
                                    width: "21em",
                                  }}
                                >
                                  {/*{stock.ModelRange} 
                              <br />*/}
                                  {stock.Model}
                                </p>
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name">
                            <span
                              className="f-13 m-t-lg-5"
                              style={{
                                float: "left",
                                textAlign: "left",
                                fontWeight: 400,
                                color: "black",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                              <img
                                src={
                                  stock.DealershipID.includes("MT003")
                                    ? `${process.env.PUBLIC_URL}/images/logo.png`
                                    : stock.DealershipID.includes("MT001")
                                      ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                      : stock.DealershipID.includes("KMK18")
                                        ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                        : stock.DealershipID.includes("MT005")
                                          ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                          : stock.DealershipID.includes("MT008")
                                            ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                            : stock.Brand.includes("GWM")
                                              ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                              : stock.Brand.includes("HAVAL")
                                                ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                : null // Return null for the default case
                                }
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "-2.5em"
                                      : stock.DealershipID.includes("MT008")
                                        ? "-2.1em"
                                        : stock.Brand.includes("HAVAL")
                                          ? "-1.8em"
                                          : "-1.9em",
                                  objectFit: "contain",
                                  width:
                                    stock.DealershipID.includes("MT003") ||
                                      stock.DealershipID.includes("MT001")
                                      ? "8.5em"
                                      : stock.DealershipID.includes("MT005")
                                        ? "7em"
                                        : stock.DealershipID.includes("MT008")
                                          ? "3em"
                                          : "5em",
                                }}
                              />
                              {stock.DealershipID.includes("MT001")
                                ? "Mega Theron Traders"
                                : stock.DealershipID.includes("MT002")
                                  ? "JAC Klerksdorp"
                                  : stock.DealershipID.includes("MT003")
                                    ? "GWM/Haval Klerksdorp"
                                    : stock.DealershipID.includes("MT004")
                                      ? "GWM/Haval Potch"
                                      : stock.DealershipID.includes("MT005")
                                        ? "Mahindra Mahikeng"
                                        : stock.DealershipID.includes("MT006")
                                          ? "GWM/Haval Mahikeng"
                                          : stock.DealershipID.includes("MT007")
                                            ? "Lichtenburg"
                                            : stock.DealershipID.includes("MT008")
                                              ? "Chery Klerksdorp"
                                              : stock.DealershipID.includes("KMK18")
                                                ? "Kia Mahikeng"
                                                : "Unknown Dealership"}
                            </span>
                            <span
                              className="f-23  m-t-lg-0 mn-cl"
                              style={{ float: "right" }}
                            >
                              <CurrencyFormat
                                value={parseFloat(stock.Price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"R"}
                                //format='R### ### ### ###'
                                renderText={(value) => <div>{value}</div>}
                              />
                            </span>
                          </div>
                          <ul className="static-caption m-t-lg-20">
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {/*{stock.fuel}*/}
                              {stock.FuelType}
                            </li>
                            <li>
                              <i className="fa fa-tachometer"></i>
                              {stock.Transmission}
                            </li>
                            <li>
                              <i className="fa fa-road"></i>
                              <CurrencyFormat
                                value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={"km"}
                                renderText={(value) => (
                                  <div style={{ display: "inline-block" }}>
                                    {value}
                                  </div>
                                )}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <Row style={{ maxWidth: "85em", margin: "auto", marginTop: "5em" }}>
            {/* <Col span={24}>
              <div
                style={{ height: "31em" }}
                className="banner-item banner-2x banner-bg-express-store  m-b-lg-50"
              >
                <div
                  className="m-b-lg-10"
                  style={{ fontSize: "20px", color: "#F91700" }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                  EXPRESS STORE
                </div>
                <h3 className="f-weight-300">
                  <strong> Safe & Secure</strong>
                  <br />
                  <span style={{ fontSize: "23px" }}>
                    Buying accessories made simple
                  </span>
                </h3>
                <p>Instant Pricing - No Hassle - Save & Secure</p>
                <a className="ht-btn ht-btn-default">Shop Now</a>
              </div>
            </Col> */}

            <Col span={24}>
              <div
                style={{ height: "27em" }}
                className="banner-item banner-2x banner-bg-service-center color-inher m-b-lg-50"
              >
                {/* <div
                  className="m-b-lg-10"
                  style={{ fontSize: "20px", color: "white" }}
                >
                  <i className="fa fa-wrench" aria-hidden="true"></i> SERVICE
                  CENTER
                </div> */}
                <h3 className="f-weight-300">
                  <strong style={{ fontSize: "16px" }}> Book a Service </strong>
                  <br /> <br />
                  <span style={{ fontSize: "23px" }}>
                    Get the service <br />
                    your car deserves
                  </span>
                </h3>
                <p>Booking online is quick and convenient</p>
                <Link to={"/servicecenter"}>
                  <a className="ht-btn ht-btn-white">Book Service</a>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <BackTop />
        <StyledModal
          // title={
          //   <span>
          //     <img
          //       className='lrg-logo'
          //       style={{ width: "12em" }}
          //       src={`${process.env.PUBLIC_URL}/images/logo.png`}
          //       alt='logo'
          //     />
          //     <h3 className='f-18' style={{ marginBottom: "-1em" }}>
          //       Finance Calculator
          //     </h3>
          //   </span>
          // }
          style={{
            top: 20,
            left: -40,
          }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Link to={"/servicecenter"}>
            <a style={{ width: "18em" }} className="ht-btn ht-btn-default">
              Book Service
            </a>
          </Link>
        </StyledModal>
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
