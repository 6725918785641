import { Calendar, Button, Form, message, Select, Spin, Tag } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const { Option, OptGroup } = Select;

function CalendarWithBooking({ onDateSelect, onBranchSelect }) {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [availableSpots, setAvailableSpots] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Track the selected date

  const fetchAvailableSpots = async (branch) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://www.devvcsappcloud.com/mtgroup/api/requisition/getAllAvailableSpots?branch=${branch}`
      );
      const data = await response.json();
      setAvailableSpots(data);
    } catch (error) {
      console.error("Error fetching available spots:", error);
      message.error("Failed to retrieve available spots");
    } finally {
      setLoading(false);
    }
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
    fetchAvailableSpots(value);
    onBranchSelect(value); // Pass the selected branch to the parent component
  };

  const handleBooking = (date, selectedBranch) => {
    const branchKey = selectedBranch;
    const dateKey = date.format("YYYY-MM-DD");
    const spots = availableSpots[dateKey] || 5;
    if (spots > 0) {
      setSelectedDate(dateKey); // Set the selected date
      message.success(`Booking selected for ${date.format("DD MMMM YYYY")}`);
    } else {
      message.error(`No spots left for ${date.format("DD MMMM YYYY")}`);
    }
    setSelectedDate(dateKey); // Track the selected date locally if needed
    onDateSelect(dateKey); // Pass the selected date to the parent component
  };

  function getListData(value) {
    const dateKey = value.format("YYYY-MM-DD");
    const spotsLeft = availableSpots[dateKey] || 5;

    return spotsLeft > 4
      ? [
          {
            type: "success",
            content: (
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#52c41a",
                    fontSize: "16px",
                  }}
                >
                  {spotsLeft}
                </span>{" "}
                Spots Left
              </span>
            ),
          },
        ]
      : spotsLeft > 0
      ? [
          {
            type: "warning",
            content: (
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#faad14",
                    fontSize: "16px",
                  }}
                >
                  {spotsLeft}
                </span>{" "}
                Spots Left
              </span>
            ),
          },
        ]
      : [
          {
            type: "error",
            content: <span style={{ color: "#ff4d4f" }}>Fully Booked</span>,
          },
        ];
  }

  function dateCellRender(value) {
    const isToday = value.isSame(moment(), "day");
    const isCurrentMonth = value.isSame(moment(), "month");
    if (!isCurrentMonth || value.isBefore(moment(), "day")) {
      return <div style={{ height: "100%" }} />;
    }

    const dateKey = value.format("YYYY-MM-DD");
    const listData = getListData(value);

    return (
      <div>
        <ul style={{ marginTop: "0.5em" }} className="events">
          {!isToday &&
            listData.map((item) => (
              <li key={item.content}>
                <Tag style={{ fontSize: "14px" }} color={item.type}>
                  {item.content}
                </Tag>
              </li>
            ))}
        </ul>
        {!isToday &&
          listData.some(
            (item) => item.type === "warning" || item.type === "success"
          ) && (
            <Button
              disabled={selectedDate === dateKey ? true : false}
              size="small"
              type="primary"
              style={{
                fontSize: "15px",
                height: "2em",
                width: "5em",
                marginTop: "0.8em",
                color: selectedDate === dateKey ? "red" : "white",
                backgroundColor: selectedDate === dateKey ? "white" : "red",
                border: selectedDate === dateKey ? "none" : "1px solid red",
                borderRadius: "0.3em",
              }}
              // onClick={() => handleBooking(value)}
            >
              {selectedDate === dateKey ? "Selected" : "Select"}
            </Button>
          )}
      </div>
    );
  }

  return (
    <div
      className="col-md-12 col-lg-12"
      style={{
        margin: "auto",
        height: selectedBranch ? "70em" : "15em",
        marginBottom: "0em",
      }}
    >
      <div className="heading-1">
        <h3 style={{ textTransform: "none" }}>
          {" "}
          Choose a Branch and Service Date
        </h3>
      </div>
      <Form.Item
        name="dealerSettingKey"
        rules={[{ required: true, message: "Please select a branch." }]}
      >
        <StyledSelect
          bordered={false}
          placeholder="Select Branch"
          onChange={handleBranchChange}
          style={{
            width: "100%",
            marginBottom: "1em",
            border: "1px solid black",
            borderRadius: "0em",
            height: 50,
          }}
        >
          <OptGroup label="Klerksdorp">
            <Option value="MT003">Klerksdorp</Option>
          </OptGroup>
          <OptGroup label="Potchefstroom">
            <Option value="MT004">Potchefstroom</Option>
          </OptGroup>
          <OptGroup label="Mahikeng">
            <Option value="Mahikeng">Mahikeng</Option>
          </OptGroup>
        </StyledSelect>
      </Form.Item>

      {loading ? (
        <Spin tip="Loading available spots..." />
      ) : (
        selectedBranch && (
          <span>
            <div
              style={{
                textAlign: "left",
                marginBottom: "1em",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              {moment().format("MMMM YYYY")}
            </div>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date for service.",
                },
              ]}
            >
              <Calendar
                defaultValue={null}
                dateCellRender={dateCellRender}
                onSelect={handleBooking}
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  const dateKey = current.format("YYYY-MM-DD");
                  return (
                    current < moment().startOf("month") ||
                    current > moment().endOf("month") ||
                    current < moment().endOf("day") ||
                    availableSpots[dateKey] === 0
                  );
                }}
              />
            </Form.Item>
          </span>
        )
      )}
    </div>
  );
}

export default CalendarWithBooking;
