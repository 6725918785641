import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterOther from "./FooterOther";
import { Carousel, Row, Spin } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Initially set loading to true
      dotPosition: "top",
      stock: props.stock,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  async componentDidMount() {
    try {
      // Fetch stock data from your local API
      // const stockResponse = await fetch("https://www.devvcsappcloud.com/mtgroup/api/requisition/extractxmldataclient", {
      //   method: "GET",
      //   mode: "cors",
      //   headers: {
      //     "Content-Type": "application/json; charset=utf-8",
      //   },
      // });
      // if (stockResponse.ok) {
      //   const stockResult = await stockResponse.json();
      //   const allStockData = stockResult.map(item => item.root.row).flat();
      //   if (allStockData.length > 0) {
      //     this.setState({ stock: allStockData, loading: false });
      //   } else {
      //     throw new Error("Unexpected response structure or empty data.");
      //   }
      // } else {
      //   throw new Error("Failed to fetch stock data.");
      // }

      // Fetch other data, like pexUnits
      const pexUnitsResponse = await fetch("https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      });
      if (pexUnitsResponse.ok) {
        const pexUnitsResult = await pexUnitsResponse.json();
        this.setState({ pexUnits: pexUnitsResult.allPexUnit, loading: false });
      } else {
        throw new Error("Failed to fetch pexUnits data.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    // Check for cached data and update state if available
    const cachedData = localStorage.getItem('cachedData');
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      this.setState({ data: parsedData });
    }
  }

  // Rest of your component code...


  renderSearchBar() {

    const { dotPosition, loading } = this.state;
    const { pexUnits } = this.props;
    return (
      <div>
        <Spin spinning={loading} indicator={antIcon} tip="Getting things ready..." size="large">
          <section className='block-sl desktop-view'>
            <div className='container-custom'>
              <div className='row'>
                <div
                  className='col-sm-5 col-md-5 col-lg-5'
                  style={{ zIndex: 10 }}
                >
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h2
                      className='f-weight-600'
                      style={{
                        fontSize: "40px",
                        marginBottom: "-1.4em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <br />
                      FIND A CAR THAT SUITS
                    </h2>

                    <h1 class='title-flip rw-sentence'>
                      <span
                      //style={{ fontFamily: "Caveat" }}
                      >
                        YOUR
                      </span>
                      <span
                        class='rw-words rw-words-1'
                      //style={{ fontFamily: "Caveat" }}
                      >
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle ? (
                            <span class='highlight'> {pexUnits.homeTitle}</span>
                          ) : null;
                        })}
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle1 ? (
                            <span class='highlight'> {pexUnits.homeTitle1}</span>
                          ) : null;
                        })}
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle2 ? (
                            <span class='highlight'> {pexUnits.homeTitle2}</span>
                          ) : null;
                        })}
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle ? (
                            <span class='highlight'> {pexUnits.homeTitle}</span>
                          ) : null;
                        })}
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle1 ? (
                            <span class='highlight'> {pexUnits.homeTitle1}</span>
                          ) : null;
                        })}
                        {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                          return pexUnits.homeTitle2 ? (
                            <span class='highlight'> {pexUnits.homeTitle2}</span>
                          ) : null;
                        })}
                      </span>
                    </h1>
                    <p
                      style={{
                        marginBottom: "-0.5em",
                        marginTop: "-1.2em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Mega Theron Group makes it easy to drive your dream car.
                    </p>
                    <Link
                      to={"/stock"}
                    // className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30 pulse-button'
                    // style={{ textTransform: "none", margin: "auto", width: "83%", textAlign: "center" }}
                    >
                      <div class='container'>
                        <span class='pulse-button'> Explore All Cars </span>
                      </div>
                    </Link>

                    {/* <Link
                    to='/ContactUs'
                    className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                    style={{
                      textTransform: "none",
                      marginLeft: "1em",
                      backgroundColor: "#201a20",
                    }}
                  >
                    Get in Touch
                  </Link> */}
                  </div>
                </div>
                <Carousel autoplay dotPosition={dotPosition} dots={false}>
                  {" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-4 col-lg-4'>
                        <img
                          className='banner-img-home'
                          style={{
                            margin: "auto",
                            marginTop: "2em",
                            height: "32em",
                            objectFit: "contain",
                            borderRadius: "1em",
                            marginBottom: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-4 col-lg-4'>
                        <img
                          className='banner-img-home'
                          style={{
                            margin: "auto",
                            marginTop: "2em",
                            height: "32em",
                            objectFit: "contain",
                            borderRadius: "1em",
                            marginBottom: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage2}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <img
                          className='banner-img-home'
                          style={{
                            margin: "auto",
                            marginTop: "2em",
                            height: "32em",
                            objectFit: "contain",
                            borderRadius: "1em",
                            marginBottom: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage3}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}
                  {/*<div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                    src={`${process.env.PUBLIC_URL}/images/home-banner-2.png`}
                    alt='logo'
                  />
                </div>*/}
                </Carousel>
              </div>
            </div>
          </section>{" "}
          <section className='block-sl mobile-view' style={{ width: "100vw" }}>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1
                      className='f-weight-600'
                      style={{
                        marginTop: "-1em",
                        fontSize: 40,
                        textAlign: "center",
                        lineHeight: 0.1,
                        marginRight: "-15px",
                        marginLeft: "-15px",
                      }}
                    >
                      <br />
                      FIND A CAR THAT
                      <h1
                        style={{
                          color: "red",
                          fontSize: 30,
                          marginTop: "0em",
                          lineHeight: 0.5,
                        }}
                      >
                        <br />
                        SUITS YOUR LIFESTYLE
                      </h1>
                    </h1>
                    <p style={{ textAlign: "center" }}>
                      Mega Theron Group makes it easy to drive your dream car.
                    </p>
                    <Row style={{ textAlign: "center" }}>
                      <Link
                        to={"/stock"}
                      // className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30 pulse-button'
                      // style={{ textTransform: "none", margin: "auto", width: "83%", textAlign: "center" }}
                      >
                        <div class='container'>
                          <span class='pulse-button'> Explore All Cars </span>
                        </div>
                      </Link>
                      {/* <Link
                      to='/ContactUs'
                      className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                      style={{
                        textTransform: "none",
                        margin: "auto",
                        marginLeft: "0em",
                        backgroundColor: "#201a20",
                      }}
                    >
                      Get in Touch
                    </Link> */}
                    </Row>
                  </div>
                </div>
                <Carousel
                  autoplay
                  dotPosition={dotPosition}
                  dots={false}
                  style={{ marginTop: "0em" }}
                >
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <img
                          className='banner-img-home'
                          style={{
                            width: "100%",
                            paddingTop: 0,
                            height: "100%",
                            objectFit: "contain",
                            margin: "auto",
                            marginBottom: "1em",
                            borderRadius: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <img
                          className='banner-img-home'
                          style={{
                            width: "100%",
                            paddingTop: 0,
                            height: "100%",
                            objectFit: "contain",
                            margin: "auto",
                            marginBottom: "1em",
                            borderRadius: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage2}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}{" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 && pexUnits.pageName === "Home Page" ? (
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <img
                          className='banner-img-home'
                          style={{
                            width: "100%",
                            paddingTop: 0,
                            height: "100%",
                            objectFit: "contain",
                            margin: "auto",
                            marginBottom: "1em",
                            borderRadius: "1em",
                            boxShadow: "0 1px 15px 0 rgb(0 0 0 / 65%)"
                          }}
                          src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnits.homeImage3}`}
                          alt='logo'
                        />
                      </div>
                    ) : null;
                  })}
                </Carousel>
              </div>
            </div>
          </section>
          {/* <SearchBarHome filters={this.getFilters.bind(this)} /> */}
        </Spin>
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      // window.location.host === "localhost:3000" ?
      // window.location.hostname === "localhost" ?
      <div id='wrap' className='color1-inher'>
        <Header />
        {location.pathname === "/home" || location.pathname === "/"
          ? this.renderSearchBar()
          : null}
        <div id='wrap-body' className='p-t-lg-45'>
          <div className='container-custom'>{this.props.children}</div>
        </div>
        {location.pathname === "/home" || location.pathname === "/"
          ? <Footer />
          : <FooterOther />}
      </div>
      //  : null
    );
  }
}
export default withRouter(SharedLayout);
