import React from "react";
import { Table, Tag, Space } from "antd";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Documents",
    dataIndex: "name",
    key: "name",
    render: (text) => <span>{text}</span>,
  },
  //{
  //  title: "Age",
  //  dataIndex: "age",
  //  key: "age",
  //},
  //{
  //  title: "Address",
  //  dataIndex: "address",
  //  key: "address",
  //},
  //{
  //  title: "Tags",
  //  key: "tags",
  //  dataIndex: "tags",
  //  render: (tags) => (
  //    <div>
  //      {tags.map((tag) => {
  //        let color = tag.length > 5 ? "geekblue" : "green";
  //        if (tag === "loser") {
  //          color = "volcano";
  //        }
  //        return (
  //          <Tag color={color} key={tag}>
  //            {tag.toUpperCase()}
  //          </Tag>
  //        );
  //      })}
  //    </div>
  //  ),
  //},
  {
    title: "Download",
    key: "action",
    render: (text, record) => (
      <Space size='middle'>
        {/*<a>Invite {record.name}</a>*/}
        <Link
          to={process.env.PUBLIC_URL + "/download/" + record.download + ".pdf"}
          target='_blank'
          download
        >
          <i
            style={{ color: "grey" }}
            className='fa fa-download'
            aria-hidden='true'
          ></i>
        </Link>
      </Space>
    ),
  },
];

const data = [
  {
    key: "1",
    name: "Affidavit i.r.o motor vehicle (Form SOA)",
    download: "Affidavit_i.r.o_motor_vehicle_(Form_SOA)",
    //tags: ["new"],
    //description: "Document Description goes here.",
  },
  {
    key: "2",
    name: "Application for Learner's Licence (Form LL1)",
    download: "Application for Learner's Licence (Form LL1)",
    //tags: ["new"],
  },
  {
    key: "3",
    name: "Application for licencing of motor vehicle (Form ALV)",
    download: "Application for licencing of motor vehicle (Form ALV)",
    //tags: ["new"],
  },
  {
    key: "4",
    name:
      "Application and notice i.r.o. approval of training centre (Form AFA)",
    download:
      "Application_and_notice_i.r.o._approval_of_training_centre_(Form_AFA)",
    //tags: ["new"],
  },
  {
    key: "5",
    name:
      "Application and notice i.r.o motor trade number and blank temporary permits (Form MTN1)",
    download:
      "Application_and_notice_i.r.o_motor_trade_number_and_blank_temporary_permits_(Form_MTN1)",
    //tags: ["new"],
  },
  {
    key: "6",
    name:
      "Application and notice i.r.o registration of a manufacturer, importer, builder of vehicle (Form MIB)",
    download:
      "Application_and_notice_i.r.o_registration_of_a_manufacturer,_importer,_builder_of_vehicle_(Form_MIB)",
    //tags: ["new"],
  },
  {
    key: "7",
    name:
      "Application and notice i.r.o registration of a manufacturer of number plates (Form MNP)",
    download:
      "Application_and_notice_i.r.o_registration_of_a_manufacturer_of_number_plates_(Form_MNP)",
    //tags: ["new"],
  },
  {
    key: "8",
    name:
      "Application and notice i.r.o registration of authorised officer (Form RO)",
    download:
      "Application_and_notice_i.r.o_registration_of_authorised_officer_(Form_RO)",
    //tags: ["new"],
  },
  {
    key: "9",
    name:
      "Application and notice i.r.o registration of driving licence testing centre (Form DTC)",
    download:
      "Application_and_notice_i.r.o_registration_of_driving_licence_testing_centre_(Form_DTC)",
    //tags: ["new"],
  },
  {
    key: "10",
    name:
      "Application and notice i.r.o registration of testing station (Form TS1)",
    download:
      "Application_and_notice_i.r.o_registration_of_testing_station_(Form_TS1)",
    //tags: ["new"],
  },
  {
    key: "11",
    name:
      "Application and notice in respect of business register number (form ABR)",
    download:
      "Application_and_notice_in_respect_of_business_register_number_(form_ABR)",
    //tags: ["new"],
  },
  {
    key: "12",
    name:
      "Application and notice in respect of traffic register number (Form ANR)",
    download:
      "Application_and_notice_in_respect_of_traffic_register_number_(Form_ANR)",
    //tags: ["new"],
  },
  {
    key: "13",
    name: "Application for certificate i.r.o.motor vehicle (Form ACV)",
    download: "Application_for_certificate_i.r.o.motor_vehicle_(Form_ACV)",
    //tags: ["new"],
  },
  {
    key: "14",
    name: "Application for deregistration of vehicle (Form ADV)",
    download: "Application_for_deregistration_of_vehicle_(Form_ADV)",
    //tags: ["new"],
  },
  {
    key: "15",
    name: "Application for driving licence (Form DL1)",
    download: "Application_for_driving_licence_(Form_DL1)",
    //tags: ["new"],
  },
  {
    key: "16",
    name:
      "Application for duplicate registration or deregistration certificate i.r.o motor vehicle (Form DRC)",
    download:
      "Application_for_duplicate_registration_or_deregistration_certificate_i.r.o_motor_vehicle_(Form_DRC)",
    //tags: ["new"],
  },
  {
    key: "17",
    name:
      "Application for initial registration of a motor vehicle (Form MVR1-MIB)",
    download:
      "Application_for_initial_registration_of_a_motor_vehicle_(Form_MVR1-MIB)",
    //tags: ["new"],
  },
  {
    key: "18",
    name:
      "Application for particulars of paper card type driving licence (Form APD)",
    download:
      "Application_for_particulars_of_paper_card_type_driving_licence_(Form_APD)",
    //tags: ["new"],
  },
  {
    key: "19",
    name: "Application for professional driving permit (Form PD1)",
    download: "Application_for_professional_driving_permit_(Form_PD1)",
    //tags: ["new"],
  },
  {
    key: "20",
    name: "Application for refund of licence fees (Form RLF)",
    download: "Application_for_refund_of_licence_fees_(Form_RLF)",
    //tags: ["new"],
  },
  {
    key: "21",
    name:
      "Application for registration and licencing of motor vehicle (Form RLV)",
    download:
      "Application_for_registration_and_licencing_of_motor_vehicle_(Form_RLV)",
    //tags: ["new"],
  },
  {
    key: "22",
    name:
      "Application for special classification i.r.o licence fees (Form ELF1)",
    download:
      "Application_for_special_classification_i.r.o_licence_fees_(Form_ELF1)",
    //tags: ["new"],
  },
  {
    key: "23",
    name: "Application for temporary, special permit (Form TSP1)",
    download: "Application_for_temporary,_special_permit_(Form_TSP1)",
    //tags: ["new"],
  },
  {
    key: "24",
    name:
      "Application in respect of licence number for motor vehicle (Form MVR2)",
    download:
      "Application_in_respect_of_licence_number_for_motor_vehicle_(Form_MVR2)",
    //tags: ["new"],
  },
  {
    key: "25",
    name: "Application to have test deferred (Form ATD)",
    download: "Application_to_have_test_deferred_(Form_ATD)",
    //tags: ["new"],
  },
  {
    key: "26",
    name: "Certificate of origin of motor vehicle (Form COO)",
    download: "Certificate_of_origin_of_motor_vehicle_(Form_COO)",
    //tags: ["new"],
  },
  {
    key: "27",
    name: "Change of particulars of notice i.r.o. motor vehicle (Form CNV)",
    download: "Change_of_particulars_of_notice_i.r.o._motor_vehicle_(Form_CNV)",
    //tags: ["new"],
  },
  {
    key: "28",
    name: "Declaration in respect of lost documents (Form DCT)",
    download: "Declaration_in_respect_of_lost_documents_(Form_DCT)",
    //tags: ["new"],
  },
  {
    key: "29",
    name: "Mass measuring certificate (Form MMC)",
    download: "Mass_measuring_certificate_(Form_MMC)",
    //tags: ["new"],
  },
  {
    key: "30",
    name: "Medical certificate (Form MC)",
    download: "Medical_certificate_(Form_MC)",
    //tags: ["new"],
  },
  {
    key: "31",
    name: "NCO",
    download: "NCO",
    //tags: ["new"],
  },
  {
    key: "32",
    name:
      "Notification of change of address or particulars of person or organisation (Form NCP)",
    download:
      "Notification of change of address or particulars of person or organisation (Form NCP)",
    //tags: ["new"],
  },
  {
    key: "33",
    name:
      "Notification of change of ownership, sale of motor vehicle (Form NCO)",
    download:
      "Notification of change of ownership, sale of motor vehicle (Form NCO)",
    //tags: ["new"],
  },
  {
    key: "34",
    name: "Notification of vehicle model (Form NVM)",
    download: "Notification_of_vehicle_model_(Form_NVM)",
    //tags: ["new"],
  },
];
const ContactUs = () => {
  if (window.innerWidth <= 770 || window.innerHeight <= 440) {
    window.isPhone = true;
  } else {
    window.isPhone = false;
  }
  return (
    <div className='w'>
      <div className='banner-item banner-2x banner-bg-download-center color-inher'>
        {" "}
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt='logo'
          style={{ width: "12em", marginBottom: "1em" }}
        />
        <h3 className='f-weight-300'>
          <strong>Download center</strong>
          <br />
          All your dealer documents in one place
        </h3>
      </div>
      <section className='block-contact m-t-lg-30 m-t-xs-0'>
        <div className=''>
          <div className='row'>
            <div
              className='col-sm-12 col-md-12 col-lg-12'
              style={{ marginBottom: window.isPhone ? "2em" : "300px" }}
            >
              <Table
                //expandable={{
                //  expandedRowRender: (record) => (
                //    <p style={{ margin: 0 }}>{record.description}</p>
                //  ),
                //  rowExpandable: (record) => record.name !== "Not Expandable",
                //}}
                pagination={false}
                columns={columns}
                dataSource={data}
              />{" "}
            </div>{" "}
            <div
              className='desktop-view'
              //className="banner-img-home"
              style={{
                width: "100vw",
                height: "50em",
                marginTop: "105em",

                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/download-banner.png` +
                  ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                left: 0,
                zIndex: -99,
                opacity: 0.8,
              }}
              //  src={`${process.env.PUBLIC_URL}/images/download-banner.png`}
              alt='logo'
            />
          </div>{" "}
        </div>{" "}
      </section>
    </div>
  );
};

export default ContactUs;
