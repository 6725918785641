import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import * as actionsKlerksdorp from "../redux/actionsKlerksdorp";
import Home from "./Home";
import SharedLayout from "./SharedLayout";
import SharedLayoutKlerksdorp from "./SharedLayoutKlerksdorp";
import Stockshop from "./StockSpace/StockShop";
import StockshopNew from "./StockSpace/StockShopNew";
import StockshopDemo from "./StockSpace/StockShopDemo";
import StockshopUsed from "./StockSpace/StockShopUsed";
import StockshopKlerksdorp from "./StockSpace/StockShopKlerksdorp";
import StockshopPotch from "./StockSpace/StockShopPotch";
import StockshopMahikeng from "./StockSpace/StockShopMahikeng";
import StockshopLichtenburg from "./StockSpace/StockShopLichtenburg";
import ProductsShop from "./ProductsSpace/ProductsShop";
import SellA_Car from "./sellA_Car";
import servicecenter from "./servicecenter";
import ContactUs from "./contactUs";
import CartFull from "./CartFull";
import StockDetails from "./StockSpace/StockDetails";
import StockDetailsKlerksdorp from "./StockSpace/StockDetailsKlerksdorp";
import ThankYou from "./thank-you";
import ThankYouService from "./thank-you-service";
import DownloadCenter from "./downloadCenter";
import WishList from "./WishList";
import Blogs from "./Blogs/blogs";
import AllPosts from "./Blogs/all-posts";
import OnePost from "./Blogs/one-post";

import LegalNotice from "./Legal/legal-notice";
import PopiaManual from "./Legal/POPIA-manual";
import PrivacyPolicy from "./Legal/privacy-policy";
import Terms from "./Legal/terms";
import EOEPOLICY from "./Legal/e&oe-policy";
import Vacancies from "./Vacancies";
import Specials from "./Specials";
import GetQuote from "./getQuote";
import BookService from "./bookService";
import GetPart from "./getPart";
import Newsletters from "./Newsletters";
import DrivingExcellence from "./driving-excellence";

const allActions = { ...actions, ...actionsKlerksdorp };

class App extends Component {
  constructor() {
    super();
    this.state = {
      stockfilters: {},
      stockfiltersKlerksdorp: {},
    };
  }

  setStockFilters(stockfilters) {
    this.setState({ stockfilters });
  }
  setStockFiltersKlerksdorp(stockfiltersKlerksdorp) {
    this.setState({ stockfiltersKlerksdorp });
  }

  render() {
    return (
      <BrowserRouter

      //basename='mtgroup'
      >

        <SharedLayout stockfilters={this.setStockFilters.bind(this)}
          stockfiltersKlerksdorp={this.setStockFiltersKlerksdorp.bind(this)}>

          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={ProductsShop} />
          <Route
            path="/stock"
            render={() => <Stockshop filters={this.state.stockfilters} />}
          />
          <Route
            path="/stock-new"
            render={() => <StockshopNew filters={this.state.stockfilters} />}
          />
          <Route
            path="/stock-demo"
            render={() => <StockshopDemo filters={this.state.stockfilters} />}
          />
          <Route
            path="/stock-used"
            render={() => <StockshopUsed filters={this.state.stockfilters} />}
          />
          <Route
            path="/potchefstroom"
            render={() => <StockshopPotch filters={this.state.stockfilters} />}
          />
          <Route
            path="/mahikeng"
            render={() => (
              <StockshopMahikeng filters={this.state.stockfilters} />
            )}
          />

          <Route
            path="/lichtenburg"
            render={() => (
              <StockshopLichtenburg filters={this.state.stockfilters} />
            )}
          />
          <Route
            path="/mega-theron-traders"
            render={() => (
              <StockshopKlerksdorp filtersKlerksdorp={this.state.stockfiltersKlerksdorp} />
            )}
          />
          <Route path="/stocks" component={StockDetailsKlerksdorp} />
          <Route path="/stocko" component={StockDetails} />
          <Route path="/thank-you" component={ThankYou} />
          <Route path="/thank-you-service" component={ThankYouService} />
          <Route path="/sellacar" component={SellA_Car} />
          <Route path="/servicecenter" component={servicecenter} />
          <Route path="/Vacancies" component={Vacancies} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/downloadCenter" component={DownloadCenter} />
          <Route path="/products_cart" component={CartFull} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/wishlist" component={WishList} />
          <Route path="/stock/vehicle-details" component={Stockshop} />
          <Route path="/products/vehicle-details" component={ProductsShop} />
          <Route path="/legal-notice" component={LegalNotice} />
          <Route path="/POPIA-manual" component={PopiaManual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/e&oe-policy" component={EOEPOLICY} />
          <Route path="/specials" component={Specials} />
          <Route path="/getQuote" component={GetQuote} />
          <Route path="/bookService" component={BookService} />
          <Route path="/getPart" component={GetPart} />
          <Route path="/Newsletters" component={Newsletters} />
          <Route path="/driving-excellence" component={DrivingExcellence} />

          <Route component={OnePost} path="/:slug"></Route>
          <Route component={AllPosts} path="/all-posts"></Route>
        </SharedLayout>
      </BrowserRouter>
    );
  }
}

export default connect(null, allActions)(App);
