import React, { Component } from "react";
//import { Row, Col, Divider } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import CurrencyFormat from "react-currency-format";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class StockItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
    };
  }
  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }
  componentDidMount() {
    var stock = this.props.stock;

    if (
      this.props.wishlistproducts.filter(function (e) {
        return e._id === stock._id;
      }).length === 1
    ) {
      this.setState({ isInWishList: true });
    }
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render() {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "#F91700",
    //    fontWeight: "bold",
    //  };
    //}

    const { stock } = this.props;
    return (
      //<Fade>
      stock.webVisible ? (
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="product-item hover-img">
            {/* <i
              id={JSON.stringify(stock)}
              //className={
              //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
              //}
              className={"fa fa-heart"}
              style={{
                background: "#ecececbe",
                borderRadius: "0em 0em 0em 0.8em",
                padding: "1em",
                position: "absolute",
                right: 0,
                top: 0,
                color: this.state.isInWishList ? "red" : "grey",
              }}
              onClick={this.ToggleCarWishlist.bind(this)}
            /> */}
            {stock.onSale === true ? (
              <span
                style={{
                  background: "rgba(249, 25, 0, 0.49)",
                  borderRadius: "5em",
                  padding: "0.7em",
                  paddingTop: "1.1em",
                  paddingBottom: "1em",
                  position: "absolute",
                  left: 10,
                  top: 10,
                  color: "white",
                  fontSize: 11,
                }}
              >
                SALE
              </span>
            ) : null}
            {stock.condition ? (
              <span
                style={{
                  background:
                    stock.condition === "New"
                      ? "rgba(249, 25, 0, 0.49)"
                      : stock.condition === "Demo"
                        ? "rgba(249, 25, 0, 0.49)"
                        : "rgba(249, 25, 0, 0.49)",
                  borderRadius: "0em",
                  padding: "0.3em",
                  paddingRight: "0.8em",
                  paddingLeft: "0.8em",
                  position: "absolute",
                  right: 10,
                  top: 158,
                  width: "4.3em",
                  color: "white",
                }}
              >
                {stock.condition.toUpperCase()}
              </span>
            ) : null}
            {stock.modelYear ? (
              <span
                style={{
                  background: "rgba(249, 25, 0, 0.49)",
                  borderRadius: "0em",
                  padding: "0.3em",
                  paddingRight: "0.8em",
                  paddingLeft: "0.8em",
                  position: "absolute",
                  right: stock.condition ? 75 : 10,
                  top: 158,
                  color: "white",
                }}
              >
                {stock.modelYear}
              </span>
            ) : null}
            <Link
              className="product-img"
              onClick={() => this.props.fetchSingleStock(stock)}
              to={`/stocks/${stock._id}`}
            >
              {stock.frontLeftImage ? (
                <img
                  src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.frontLeftImage}`}
                  alt=""
                />
              ) : (
                <img
                  src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                  alt=""
                />
              )}
            </Link>
            <div className="product-caption">
              <h4
                className="product-name"
                style={{
                  marginBottom: "0em",
                  marginTop: "-1em",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                <Link
                  onClick={() => this.props.fetchSingleStock(stock)}
                  to={`/stocks/${stock._id}`}
                >
                  <span style={{ display: "block" }}>
                    {stock.make} {stock.series}
                    <p
                      className="f-14"
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        paddingRight: "3em",
                        overflow: "hidden",
                        width: "21em",
                      }}
                    >
                      {/*{stock.series} 
                    <br />*/}
                      {stock.modelDesc}
                    </p>
                  </span>
                </Link>
              </h4>{" "}
              <div className="product-name">
                <span
                  className="f-15 m-t-lg-5"
                  style={{
                    float: "left",
                    fontWeight: 400,
                    color: "black",
                    marginLeft: window.isPhone ? "-1em" : "",
                  }}
                >
                  {stock.branchImage ? (
                    <img
                      src={`https://www.devvcsappcloud.com/mtgroup/api/images/${stock.branchImage}`}
                      alt=""
                      style={{
                        position: "absolute",
                        marginTop: "-2.5em",
                        objectFit: "cover",
                        height: "2em",
                        left: 0,
                      }}
                    />
                  ) : (
                    <img
                      src={
                        stock.brand === "Mega Theron Group" ? (
                          `${process.env.PUBLIC_URL}/images/logo.png`
                        ) : stock.brand === "Mega Theron Traders" ? (
                          `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                        ) : stock.brand === "Kia" ? (
                          `${process.env.PUBLIC_URL}/images/kia-image.png`
                        ) : stock.brand === "Mahindra" ? (
                          `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                        ) : stock.brand === "Chery" ? (
                          `${process.env.PUBLIC_URL}/images/chery-image.png`
                        ) : stock.brand === "Gwm" ? (
                          `${process.env.PUBLIC_URL}/images/gwm-image.png`
                        ) : stock.brand === "Haval" ? (
                          `${process.env.PUBLIC_URL}/images/haval-image.png`
                        ) : stock.brand === "JAC" ? (
                          `${process.env.PUBLIC_URL}/images/jac-image.png`
                        ) : (
                          <span
                            style={{
                              textAlign: "left",
                              position: "absolute",
                              marginTop: window.isPhone ? "-2em" : "-1.5em",
                              whiteSpace: "nowrap",
                              left: 0,
                            }}
                          >
                            {stock.brand}
                          </span>
                        )
                      }
                      alt=""
                      style={{
                        position: "absolute",
                        marginTop:
                          stock.brand === "Mega Theron Group" ||
                            stock.brand === "Mega Theron Traders"
                            ? "-2.5em"
                            : stock.brand === "Chery"
                              ? "-2.1em"
                              : stock.brand === "Haval"
                                ? "-1.8em"
                                : "-1.9em",
                        objectFit: "contain",
                        width:
                          stock.brand === "Mega Theron Group" ||
                            stock.brand === "Mega Theron Traders"
                            ? "8.5em"
                            : stock.brand === "Mahindra"
                              ? "7em"
                              : stock.brand === "Chery"
                                ? "3em"
                                : "5em",
                      }}
                    />
                    //<span
                    //  style={{
                    //    textAlign: "left",
                    //    position: "absolute",
                    //    marginTop: window.isPhone ? "-2em" : "-1.5em",
                    //    whiteSpace: "nowrap",
                    //    left: 0,
                    //  }}
                    //>
                    //  {stock.brand}
                    //</span>
                  )}
                  {stock.branch}
                </span>
                <span
                  className="f-23  m-t-lg-0 mn-cl"
                  style={{ float: "right" }}
                >
                  <CurrencyFormat
                    value={stock.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"R"}
                    //format='R### ### ### ###'
                    renderText={value => <div>{value}</div>}
                  />
                </span>
              </div>
              <ul className="static-caption m-t-lg-20">
                <li>
                  <i className="fa fa-clock-o"></i>
                  {/*{stock.fuel}*/}
                  {stock.fuelType}
                </li>
                <li>
                  <i className="fa fa-tachometer"></i>
                  {stock.transmission}
                </li>
                <li>
                  <i className="fa fa-road"></i>
                  <CurrencyFormat
                    value={stock.mileage}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={" km"}
                    //format='R### ### ### ###'
                    renderText={value => (
                      <div style={{ display: "inline-block" }}>{value}</div>
                    )}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null
      //</Fade>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(StockItemCard);