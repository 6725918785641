import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

class DrivingExcellence extends Component {
  render() {
    return (
      <div className="w">
        <div className="banner-item banner-2x banner-bg-download-center color-inher">
          {" "}
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="logo"
            style={{ width: "12em", marginBottom: "1em" }}
          />
          <h3
            className="f-weight-300"
            style={{
              lineHeight: 0,
            }}
          >
            <strong>
              Driving{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                Excellence
              </span>
            </strong>
            <br />
            <span
              style={{
                fontSize: window.isPhone ? 18 : 25,
                fontWeight: 300,
                lineHeight: "1em",
              }}
            >
              Mega Theron Group's Journey to Record-Breaking Success
            </span>
          </h3>
        </div>
        <Row
          style={{
            maxWidth: window.isPhone ? "" : "60em",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Col
            xs={24}
            md={24}
            style={{
              margin: "auto",
            }}
          >
            <img
              style={{
                boxShadow: "2px 2px 10px 2px lightgrey",
                margin: "auto",
                width: window.isPhone ? "99%" : "",
              }}
              src={`${process.env.PUBLIC_URL}/newsletter-images/driving-excellence-newsletter.png`}
              alt="newsletter-pdf"
            />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "60em",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Col
            style={{
              margin: "auto",
            }}
          >
            <Link to={"/Newsletters"}>
              <Button
                style={{
                  border: "1px solid #201a20",
                  fontSize: "15px",
                  fontWeight: 600,
                  borderRadius: "0.3em",
                  backgroundColor: "red",
                  borderColor: "red",
                  color: "white",
                  height: "55px",
                  minWidth: window.isPhone ? "96vw" : "53em",
                  paddingTop: "0.2em",
                  paddingLeft: "3em",
                  paddingRight: "3em",
                  textShadow: "none",
                  marginTop: "3em",
                }}
                type="primary"
              >
                Back to Newsletters
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DrivingExcellence;
