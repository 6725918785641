import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, List, Typography, Button, Empty } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "top",
      // pexUnits: props.pexUnits,
      bookingNumber: '', // Initialize the state
      // pexUnits: [],
      // AllPexUnit: [],

    };

  }

  componentDidMount() {
    // Extract lead reference from URL and set it in state
    const urlParams = new URLSearchParams(window.location.search);
    const bookingNumber = urlParams.get('bookingNumber');
    this.setState({ bookingNumber });
  }


  render() {
    const { location } = this.props;

    return (
      <div id='wrap' className='color1-inher'>
        <div>
          <section className='block-sl'> <div className='banner-item banner-2x banner-bg-download-center color-inher'>
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt='logo'
              style={{ width: "14em", marginBottom: "1em" }}
            />
            <h3 className='f-weight-300'>
              <strong>   Thank you for contacting</strong>
              <br />
              The Mega Theron Group

              <br />
              <br />
              <span style={{ color: "black ", fontSize: "17px" }}>
                Our vehicle specialist will be in touch shortly
              </span>{" "}
              <br />
              <p style={{ color: "rgb(214, 54, 41)", fontSize: "14px", margin: "auto", fontWeight: 600 }}>
                {this.state.bookingNumber && (
                  <p>Your Booking Reference Code: <br />{this.state.bookingNumber}</p>
                )}

              </p>{" "}
            </h3>{" "}
          </div>
          </section > {" "}

        </div >


      </div>
    );
  }
}
export default withRouter(SharedLayout);
