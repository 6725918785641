import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import App from "./components/App";
import reducers from "./redux/reducers";
import { DataProvider } from "./DataContext"; // Import your DataProvider

// Development only axios helpers!
import axios from "axios";
window.axios = axios;

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    {/* <DataProvider>  */}
    <App />
    {/* </DataProvider> */}
  </Provider>,
  document.querySelector("#root")
);
