import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProducts } from "../../redux/actions";

import ProductsContainer from "./ProductsContainer";
import ProductDetails from "./ProductDetails";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class ProductsShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: "TYRES&WHEELS",
    };
  }

  componentDidMount() {
    this.props.fetchProducts("TYRES&WHEELS");
  }

  renderFiltredProducts(activePage) {
    this.setState({ activePage });
    this.props.fetchProducts(activePage);
  }

  render() {
    return (
      <div
        className='wrap-body-inner'
        style={
          {
            // minHeight: "1200px"
          }
        }
      >
        {" "}
        <img
          //className="banner-img-home"
          style={{
            width: "106vw",
            position: "absolute",
            height: "35em",
            objectFit: "cover",
            marginTop: "0em",
            right: 0,
            zIndex: 0,
          }}
          src={`${process.env.PUBLIC_URL}/images/shop-banner.png`}
          alt='logo'
        />
        <div className='row m-b-lg-80 '>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='banner-item-home banner-2x-home no-bg color-inher-home text-align-lg-right '>
              <h1 style={{ color: "white" }} className={window.isPhone ? "f-weight-600 f-20" : 'f-weight-600 f-20'}>
                QUALITY BEYOND EXCELLENCE
              </h1>
              <h1 style={{ color: "white" }} className={window.isPhone ? "f-weight-600 f-30" : 'f-weight-600 f-50'}>
                BUY TRUSTWORTHY <br />
              </h1>
              <h1
                style={{ color: "red", textTransform: "none" }}
                className={window.isPhone ? 'f-weight-400 f-30' : 'f-weight-400 f-50'}
              >
                STOCK ACCESSORIES
              </h1>

              <p style={{ color: "white" }}>
                Mega Theron makes it easy to get the Parts & Service your stock
                deserves, at the best prices possible.
              </p>
              <a
                href='#shop'
                className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                style={{ textTransform: "none" }}
              >
                Shop Now
              </a>
              {/*</div>*/}
            </div>
          </div>
        </div>
        <section id='shop' className='m-t-lg-30 m-t-xs-0 m-t-lg-50'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 ' style={{ marginTop: "3em" }}>
              <div id='my-store-79975002'></div>
            </div>
          </div>

        </section>
      </div>
    );
  }
}

function mapStateToProps({ products }) {
  return { products };
}

export default connect(mapStateToProps, { fetchProducts })(ProductsShop);
