import { combineReducers } from "redux";

import productsReducer from "./productsReducer";
import pathReducer from "./pathReducer";
import selectedProductReducer from "./currentProdReducer";
import basketproductsReducer from "./basketReducer";
import stockReducer from "./stockReducer";
import wishListReducer from "./wishListReducer";
import selectedStockReducer from "./currentCarReducer";
import fetchedStockReducer from "./fetchedStockReducer";
import filteractivationReducer from "./filterReducer";

export default combineReducers({
  locationPath: pathReducer,
  products: productsReducer,
  selectedProduct: selectedProductReducer,
  selectedStock: selectedStockReducer,
  basketproducts: basketproductsReducer,
  stock: stockReducer,
  wishlistproducts: wishListReducer,
  fetchedStock: fetchedStockReducer,
  homefilterActivated: filteractivationReducer,
});
