import axios from "axios";
import $ from "jquery";
import {
  FETCH_FILTRED_STOCK,
  FETCH_PRODUCTS,
  BASKET_ADD,
  BASKET_REMOVE_ITEM,
  FETCH_STOCK,
  FETCH_SINGLE_STOCK,
  BASKET_EDIT_ITEM,
  WISHLIST_ADD_PRODUCT,
  WISHLIST_REMOVE_PRODUCT,
  SELECTED_PRODUCT,
  SELECTED_STOCK,
  FILTER_ACTIVATION,
} from "./types";

export const fetchProducts = category => async dispatch => {
  const res = await axios.get(`/api/products/:${category}`);
  dispatch({ type: FETCH_PRODUCTS, payload: res.data });
};

export function fetchSingleProduct(product) {
  return {
    type: SELECTED_PRODUCT,
    payload: product,
  };
}

export function fetchSingleStock(product) {
  console.log(product);
  return {
    type: SELECTED_STOCK,
    payload: product,
  };
}

export function addToBasket(product, quantity) {
  return {
    type: BASKET_ADD,
    payload: {
      product,
      quantity,
    },
  };
}

export function removeFromBasket(id) {
  return {
    type: BASKET_REMOVE_ITEM,
    payload: id,
  };
}

export function changeBasketItem(product, quantity) {
  return {
    type: BASKET_EDIT_ITEM,
    payload: {
      product,
      quantity,
    },
  };
}

export function addToWishList(product) {
  return {
    type: WISHLIST_ADD_PRODUCT,
    payload: product,
  };
}

export function removeFromWishList(product) {
  return {
    type: WISHLIST_REMOVE_PRODUCT,
    payload: product,
  };
}
//export function fetchStock() {
//  return (dispatch) =>
//    new Promise((resolve, reject) => {
//      fetch(
//        "https://www.devvcsappcloud.com/mtgroup/api/stock/findallstockclient",
//        {
//          method: "GET",
//          mode: "cors",
//        }
//      ).then(async (res) => {
//        if (!res.ok) {
//          return reject({ message: await res.text() });
//        }
//        return resolve(
//          dispatch({
//            type: "FETCH_STOCK",
//            data: await res.json(),
//          })
//        );
//      });
//    }).catch((err) => {
//      throw err.message;
//    });
//}
//export const fetchStock = () => async (dispatch) => {
//  const res = await axios.get("/api/stock");
//  dispatch({ type: FETCH_STOCK, payload: res.data });
//};
export function fetchStock() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch("https://www.devvcsappcloud.com/mtgroup/api/requisition/extractxmldataclient", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + sessionStorage.token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Process the received data
        console.log(data);
        const allStockData = data.map(item => item.root.row).flat();

        // Apply additional data processing or filtering logic here if needed
        const filteredData = allStockData.filter(row => row);

        resolve(filteredData);
      } else {
        // Handle error response
        console.error("Failed to fetch data:", response.statusText);
        reject(response.statusText);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error occurred while fetching data:", error);
      reject(error);
    }
  });
}

export const fetchStockById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/stock/${id}`);
    dispatch({ type: FETCH_SINGLE_STOCK, payload: res.data });
  } catch (error) {
    console.error("Error occurred while fetching single stock:", error);
    // Handle error - dispatch an action or log it accordingly
  }
};

export const fetchFiltredStock = filters => async dispatch => {
  try {
    const fetchedStock = await fetchStock();
    console.log("**********************************");
    console.log(filters);
    console.log("**********************************");
    // Destructure filters
    const {
      ModelRange,
      NewUsed,
      BodyType,
      Brand,
      Year,
      Model,
      Mileage,
      Transmission,
      vehicleDrive,
      Features,
      DealershipName,
      Colour,
      FuelType,
      onSale,
      priceRange = "",
      mileageRange = "",
      yearRange = "",
    } = filters;
    var result = {};

    result = fetchedStock;
    // if (NewUsed != undefined && NewUsed[0] !== "" && NewUsed.length > 0) {
    //   result = result.filter(function (stock) {
    //     for (var i = 0; i < NewUsed.length; i++) {
    //       if (stock.NewUsed === NewUsed[i]) {
    //         return true;
    //       }
    //     }
    //   });
    // }
    if (NewUsed != undefined && NewUsed[0] !== "" && NewUsed.length > 0) {
      result = result.filter(function (stock) {
        return NewUsed.includes(stock.NewUsed[0]);
      });
    }
    if (ModelRange != undefined && ModelRange[0] !== "" && ModelRange.length > 0) {
      result = result.filter(function (stock) {
        return ModelRange.includes(stock.ModelRange[0]);
      });
    }
    if (BodyType != undefined && BodyType[0] !== "" && BodyType.length > 0) {
      result = result.filter(function (stock) {
        return BodyType.includes(stock.BodyType[0]);
      });
    }
    if (Brand != undefined && Brand[0] !== "" && Brand.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return Brand.includes(stock.Brand[0]);
      });
    }

    if (Year != undefined && Year[0] !== "" && Year.length > 0) {
      result = result.filter(function (stock) {
        for (var i = 0; i < Year.length; i++) {
          if (stock.Year === Year[i]) {
            return true;
          } else if (stock.Year === Year) {
            return stock.Year === Year;
          }
        }
      });
    }
    if (Transmission != undefined && Transmission[0] !== "" && Transmission.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return Transmission.includes(stock.Transmission[0]);
      });
    }
    if (vehicleDrive != undefined && vehicleDrive[0] !== "" && vehicleDrive.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return vehicleDrive.includes(stock.vehicleDrive[0]);
      });
    }

    if (DealershipName != undefined && DealershipName[0] !== "" && DealershipName.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return DealershipName.includes(stock.DealershipName[0]);
      });
    }
    if (Colour != undefined && Colour[0] !== "" && Colour.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return Colour.includes(stock.Colour[0]);
      });
    }
    if (FuelType != undefined && FuelType[0] !== "" && FuelType.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return FuelType.includes(stock.FuelType[0]);
      });
    }
    if (Model != undefined && Model[0] !== "" && Model.length > 0) {
      result = result.filter(function (stock) {
        // Check if the Brand property of the stock item is included in the Brand array
        return Model.includes(stock.Model[0]);
      });
    }
    if (onSale != undefined && onSale !== false)
      result = result.filter(function (stock) {
        return stock.onSale === true;
      });
    // if (Model != undefined && Model[0] !== "" && Model.length > 0) {
    //   result = result.filter(function (stock) {
    //     for (var i = 0; i < Model.length; i++) {
    //       if (stock.Model === Model[i]) {
    //         return true;
    //       } else if (stock.Model === Model) {
    //         return stock.Model === Model;
    //       }
    //     }
    //   });
    // }
    //if (Mileage != undefined && Mileage !== "")
    //  result = result.filter(function (stock) {
    //    return stock.Mileage === Mileage;
    //  });
    //Slider
    var min = 1000 * priceRange.slice(1, priceRange.indexOf(","));
    var max =
      1000 *
      priceRange.slice(
        priceRange.indexOf("-") + 3,
        priceRange.indexOf(",", priceRange.indexOf("-"))
      );

    console.log(min);
    console.log(max);
    if (priceRange != undefined && priceRange !== "")
      result = result.filter(function (stock) {
        console.log(
          parseInt(stock.Price, 10) <= max && parseInt(stock.Price, 10) >= min
        );
        return (
          parseInt(stock.Price, 10) <= max && parseInt(stock.Price, 10) >= min
        );
      });
    //Slider-Mileage
    var min = 1000 * mileageRange.slice(1, mileageRange.indexOf(","));
    var max =
      1000 *
      mileageRange.slice(
        mileageRange.indexOf("-") + 3,
        mileageRange.indexOf(",", mileageRange.indexOf("-"))
      );

    console.log(min);
    console.log(max);
    if (mileageRange === undefined && mileageRange === "")
      result = result.filter(function (stock) {
        console.log(
          parseInt(stock.Mileage, 10) <= max && parseInt(stock.Mileage, 10) >= min
        );
        return (
          parseInt(stock.Mileage, 10) <= max && parseInt(stock.Mileage, 10) >= min
        );
      });
    //Slider-Year
    var minYear = 1 * yearRange.slice(1, yearRange.indexOf(" -  ") - 0);
    var maxYear = 1 * yearRange.slice(yearRange.indexOf("-") + 2);

    console.log(minYear);
    console.log(maxYear);
    if (yearRange != undefined && yearRange !== "")
      result = result.filter(function (stock) {
        console.log(
          parseInt(stock.Year, 10) <= maxYear &&
          parseInt(stock.Year, 10) >= minYear
        );
        return (
          parseInt(stock.Year, 10) <= maxYear &&
          parseInt(stock.Year, 10) >= minYear
        );
      });
    dispatch({ type: FETCH_FILTRED_STOCK, payload: result });
  } catch (error) {
    console.error("Error occurred while fetching and filtering stock:", error);
    // Handle error - dispatch an action or log it accordingly
  }
};

export function turnOnHomefilter() {
  return {
    type: FILTER_ACTIVATION,
    payload: true,
  };
}

export function turnOffHomefilter() {
  return {
    type: FILTER_ACTIVATION,
    payload: false,
  };
}
export function setCarConditionUsed() {
  return {
    type: FILTER_ACTIVATION,
    payload: true,
  };
}
